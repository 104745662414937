import { Component, OnInit, HostListener, Input } from '@angular/core';
import * as d3 from "d3";
import { common } from 'src/app/common/common';
import { AJWebService } from 'src/services/ajWeb.service';

@Component({
  selector: 'mri-bullet-chart3',
  templateUrl: './bullet-chart.component.html',
  styleUrls: ['./bullet-chart.component.scss']
})
export class BulletChartComponent implements OnInit {
  data: any;
  @Input() chartIdSelector;
  @Input() opportunityId: number;
  measures: number;
  markers: number;

  margin = { top: 10, bottom: 20, left: 5, right: 10 };
  svg: any;
  width: number;
  height: number;
  graphWidth: number;
  chart: any;
  bar_width = 8;
  rx = this.bar_width / 2;
  ry = this.bar_width / 2;
  rangeVal = [];
  rangeColor = ['#F0F0F1', '#e3e6e8', '#cbd0d6', '#bac0cc', '#aab0bd'];
  legendKey = ["Planned", "Completed"];
  legendColor = ["#9657D5", "#B95FD2"];
  xAxisLabel = "Total Savings ($)";

  constructor(public ajWebService: AJWebService) { }

  ngOnInit() {
    this.opportunityId = +this.chartIdSelector.substring(this.chartIdSelector.lastIndexOf('-') + 1);
    this.displayGraph();
    this.ajWebService.savingsAndFTEDataUpdated.subscribe(opportunityId => {
      this.opportunityId = +opportunityId;
      this.displayGraph();
    }, error => {
      this.ajWebService.showErrorMessage(error);
    })
  }

  displayGraph() {
    common.clearSVG(this.chartIdSelector);
    this.getData();
    //this.getUsage();
  }

  getUsage() {
    this.ajWebService.GetUsage(this.opportunityId).subscribe(data => {
      console.log(data)
    },
      error => {
        console.log(error)
      })
  }

  getData() {
    this.ajWebService.GetSavingsValue(this.opportunityId).subscribe(data => {
      if (data != undefined) {
        this.measures = data["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next);
        for (var i = 0; i < this.ajWebService.opportunityQueues.length; i++)
          if (this.ajWebService.opportunityQueues[i]["Complete"] != undefined && this.ajWebService.opportunityQueues[i]["Complete"].length > 0)
            for (var j = 0; j < this.ajWebService.opportunityQueues[i]["Complete"].length; j++)
              if (this.ajWebService.opportunityQueues[i]["Complete"][j]["OpportunityId"] == this.opportunityId) {
                this.markers = (data["SavedCost"] / 12) * this.monthDiff(new Date(this.ajWebService.opportunityQueues[i]["Complete"][j]["PlannedStartDate"]), new Date());
                break;
              }

        this.data = [{
          "title": "Actual",
          "measures": this.measures,
          "markers": this.markers
        }];
        this.drawGraph();
      }
    },
      error => {
        console.log(error)
      })
  }

  monthDiff(date, currentDate) {
    var months = (currentDate.getFullYear() - date.getFullYear()) * 12;
    months -= date.getMonth();
    months += currentDate.getMonth();
    return months <= 0 ? 0 : months;
  }

  drawGraph() {
    this.renderSVGContainer();
    this.renderXAxis();
    this.renderRangeRect();
    this.renderMeasure();
    this.renderMark();
    this.renderAxisText();
    //this.renderLegend();
  }

  renderSVGContainer() {
    let elem = <HTMLElement>document.getElementById(this.chartIdSelector);
    if (elem != undefined)
      elem.innerHTML = "";
    
    this.width = 220;
    this.height = 80;
    this.svg = common.getSVG(this.chartIdSelector, this.width + 19, this.height - 10);
    this.graphWidth = this.width - 10;
    // this.svg = common.getSVG(this.selector, '80', '80');

    // this.width = 100 //elem.offsetWidth - this.margin.left - this.margin.right;
    // this.height = 100; //elem.offsetHeight - this.margin.top - this.margin.bottom;

    // this.graphWidth = 100;
  }

  xScale() {
    return d3.scaleLinear()
      .range([0, this.graphWidth])
      .domain([0, Math.round(1.2 * Math.max(this.measures, this.markers))])
      .nice();
  }

  yScale() {
    return d3.scaleBand()
      .range([0, this.height])
      .padding(0.2);
  }

  renderChartContainer() {
    return this.chart = this.svg
      .append("g")
      .attr(
        "transform",
        "translate(" + [this.margin.left, this.margin.top - 70] + ")"
      );
  }

  renderXAxis() {

    if (this.data.length == 1 && this.data[0].measures == 0 && this.data[0].markers == 0) {
      this.renderChartContainer().append("g")
        .attr("transform", `translate(-105, ${this.height + 10})`)
        .call(d3.axisBottom(this.xScale()).tickSizeOuter(0)
          .ticks(4).tickFormat(
            d => d >= 1000 ? +d / 1000 + 'K' : d + ''
          ))
        .call((d) => {
          d.selectAll("path").remove();
          d.selectAll("line").remove();
          d.selectAll("text")
            .attr("font-size", "10px")
        });
    }
    else {
      this.renderChartContainer().append("g")
        .attr("transform", `translate(0, ${this.height + 10})`)
        .call(d3.axisBottom(this.xScale()).tickSizeOuter(0)
          .ticks(4).tickFormat(
            d => d >= 1000 ? +d / 1000 + 'K' : d + ''
          ))
        .call((d) => {
          d.selectAll("path").remove();
          d.selectAll("line").remove();
          d.selectAll("text")
            .attr("font-size", "10px")
        });
    }
  }

  renderRangeRect() {
    let maxValue = Math.round(1.2 * Math.max(this.measures, this.markers));
    this.rangeVal = [];
    for (var i = 5; i >= 1; i--)
      this.rangeVal.push(maxValue * i / 5);

    var range = this.renderChartContainer()
      .selectAll()
      .data(this.rangeVal)
      .enter()
      .append("g")

    range.append('rect')
      .attr('class', 'in-range')
      .attr("x", -5)
      .attr('y', this.yScale().bandwidth() - this.rx * 2 + 2)
      .attr('height', this.bar_width * 3)
      .attr('width', (d) => this.xScale()(d) + 8)
      .attr('fill', (d, i) => this.rangeColor[i]);

    range.append('rect')
      .attr('class', 'out-range')
      .attr("x", -5)
      .attr('y', this.yScale().bandwidth() - this.rx * 2 + 2)
      .attr('rx', this.rx)
      .attr('ry', this.ry)
      .attr('height', this.bar_width * 3)
      .attr('width', this.graphWidth + 8)
      .attr('fill', 'none')
      .attr('stroke', 'white')
      .attr('stroke-width', 8);
  }

  renderMeasure() {
    var mes = this.renderChartContainer()
      .selectAll()
      .data(this.data)
      .enter()
      .append("g")
      .attr("class", "measure")
      .style("fill", this.legendColor[1]);

    if (this.data.length == 1 && this.data[0].measures == 0 && this.data[0].markers == 0) {
      mes.append("path")
        .attr("d", (d, i) => {
          return `M${0} ${this.yScale().bandwidth() - this.ry + 4}
                h${2.359375}
                a${this.rx} ${this.ry} 0 0 1 ${this.rx} ${this.ry}
                v 4
                a${this.rx} ${this.ry} 0 0 1 ${-this.rx} ${this.ry}
                h${-2.359375}Z`
        }
        ).append('svg:title')
        .text(d =>
          d["measures"]
        );
    }
    else {
      mes.append("path")
        .attr("d", (d, i) => {
          return `M${0} ${this.yScale().bandwidth() - this.ry + 4}
              h${(this.xScale()(d.measures) < this.rx ? this.rx - this.xScale()(d.measures) : this.xScale()(d.measures) - this.rx)}
              a${this.rx} ${this.ry} 0 0 1 ${this.rx} ${this.ry}
              v 4
              a${this.rx} ${this.ry} 0 0 1 ${-this.rx} ${this.ry}
              h${(this.xScale()(d.measures) < this.rx ? this.xScale()(d.measures) - this.rx : this.rx - this.xScale()(d.measures))}Z`
        }
        ).append('svg:title')
        .text(d =>
          d["measures"]
        );
    }
  }

  renderMark() {

    if (this.data.length == 1 && this.data[0].measures == 0 && this.data[0].markers == 0) {
      let mark = this.renderChartContainer()
        .selectAll(".marker")
        .data(this.data)
        .enter()
        .append('g')
        .attr("transform",
          "translate(-105,0)")
        .attr("class", "marker")

      mark.append("line")
        .attr("x1", d => this.xScale()(d.markers))
        .attr("x2", d => this.xScale()(d.markers))
        .attr("y1", this.yScale().bandwidth() - this.bar_width + 6)
        .attr("y2", this.yScale().bandwidth() + this.bar_width + 6)
        .attr("stroke-width", 3)
        .attr("stroke", this.legendColor[0])
        .attr('fill', this.legendColor[0])
        .append('svg:title')
        .text(d =>
          d["markers"]
        );
    }
    else {
      let mark = this.renderChartContainer()
        .selectAll(".marker")
        .data(this.data)
        .enter()
        .append('g')
        .attr("class", "marker")

      mark.append("line")
        .attr("x1", d => this.xScale()(d.markers))
        .attr("x2", d => this.xScale()(d.markers))
        .attr("y1", this.yScale().bandwidth() - this.bar_width + 6)
        .attr("y2", this.yScale().bandwidth() + this.bar_width + 6)
        .attr("stroke-width", 3)
        .attr("stroke", this.legendColor[0])
        .attr('fill', this.legendColor[0])
        .append('svg:title')
        .text(d =>
          d["markers"]
        );
    }
  }

  renderAxisText() {
    this.renderChartContainer().append("text")
      .attr("transform",
        "translate(" + (this.graphWidth / 2) + " ," + (this.height + 70) + ")")
      .attr("text-anchor", "middle")
      .attr("font-family", "sans-serif Medium")
      .attr("font-size", "12px")
      .text();
  }


  renderLegend() {
    var nodeWidth = (d) => d.getBoundingClientRect().width;
    const legend = this.renderChartContainer().append('g')
      .attr('class', 'legend')
      .attr('transform', 'translate(-50,0)');

    const lg = legend.selectAll('g')
      .data(this.legendKey)
      .enter()
      .append('g')
      .attr("class", (d, i) => "circlelegend" + i)

    lg.append('circle')
      .attr("id", (d, i) => "circle" + i)
      .attr('fill', (d, i) => this.legendColor[i])
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 5)

    lg.append('text')
      .attr("font-family", "sans-serif")
      .attr("font-size", "11px")
      .attr('fill', '#515151')
      .attr('x', 10)
      .attr('y', 5)
      .attr('dy', -1)
      .text((d, i) => this.legendKey[i]);

    let offset = 0;
    const self = this;
    lg.attr('transform', function (d, i) {
      let x = offset;
      offset += nodeWidth(this) + 10;
      return `translate(${x - 67},${self.height + 10})`;
    });

    legend.attr('transform', function () {
      return `translate(${(self.graphWidth - nodeWidth(this) - 5)},${30})`
    });
  }

  // @HostListener("window:resize", ["$event"])
  // onResize(event) {
  //   common.clearSVG(this.chartIdSelector);
  //   this.drawGraph();
  // }
}
