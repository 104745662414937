import { of as observableOf, Observable } from 'rxjs';
import { Injectable, Output, EventEmitter } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Location } from '@angular/common';
import { Router } from "@angular/router";
import { AppConfigService } from './app-config.service';

declare var bootbox: any;
//import 'rxjs/Rx';

@Injectable()
export class AJWebService {
    public updateScopeSelector = new EventEmitter<{ cmsOrWS, cmsOrWSUid, wsUid, cmsOrWSId }>();

    public displayROIGraphPopUp = new EventEmitter<string>();
    public displayTicketVolumeGraphPopUp = new EventEmitter<string>();
    public displaySavingsGraphPopUp = new EventEmitter<string>();
    public displayComment = new EventEmitter<string>();

    public enterpriseId: string;
    public endToEndUId: string;
    public clientWMSId: string;

    public tabCode: string;
    public FilteredCMSWSRequest: any;
    public LoadOpportunityRequest: any;
    public CMSUId: string;
    public WorkstreamUId: string;
    public queryString: any;
    public role: string;
    public ScopeSelectorLoaded: boolean;
    public isError: boolean;
    public ErrorMessage: string;
    public DAvisible: boolean;
    public LaunchAnalyservisible: boolean;
    public userSpecificUsage: string;

    public plannedTotalEfforts: any;
    public costPerHour: any;
    public actualTotalSavings: any;
    public plannedTotalSavings: any;
    public outcomesDataCalculationCompleted = new EventEmitter<string>();

    public ticketVolumeDataUpdated = new EventEmitter<string>();
    public savingsAndFTEDataUpdated = new EventEmitter<string>();
    public lastActiveOpportunityId: number;

    public showAutomationOpportunitiesData = new EventEmitter<string>();
    public ticketDistributionData = [];
    public ticketDistributionDataLoaded = new EventEmitter<string>();
    public opportunityQueues: any[][] = [];
    public opportunityQueuesLoaded = new EventEmitter<string>();
    public showTicketDistributionChartInPopUp = new EventEmitter<string>();
    public showUseCasesImplementationChartInPopUp = new EventEmitter<string>();
    public loadAssetProvisioningData = new EventEmitter<string>();
    public loadAutomationOpportunityData = new EventEmitter<string>();

    public backlogList: any[] = [];
    public inProgressList: any[] = [];
    public completedList: any[] = [];
    public cancelledList: any[] = [];

    public clientList:any;
    public backlogassetList: any[] = [];
    public inProgressassetList: any[] = [];
    public completedassetList: any[] = [];
    public cancelledassetList: any[] = []
   
    // enterpriseId(enterpriseId: any, endToEndUId: any, clientWMSId: any) {
    //   throw new Error("Method not implemented.");
    // }
    // endToEndUId(enterpriseId: any, endToEndUId: any, clientWMSId: any) {
    //   throw new Error("Method not implemented.");
    // }
    // clientWMSId(enterpriseId: any, endToEndUId: any, clientWMSId: any) {
    //   throw new Error("Method not implemented.");
    // }
    public baseURL: string;
    public environmentDetails: any;
    public cmsData :any;

    @Output() change: EventEmitter<boolean> = new EventEmitter();
    public onClear: EventEmitter<boolean> = new EventEmitter();

    constructor(location: Location, router: Router, private http: HttpClient, private appConfig: AppConfigService) {
        this.environmentDetails = this.appConfig.environmentSettings;
        if (this.environmentDetails.MSALenabled)
            this.baseURL = this.environmentDetails.serverAPIUrl;
        else
            this.baseURL = this.environmentDetails.localAPIUrl;

        this.ScopeSelectorLoaded = false;
        if (this.baseURL.includes("localhost")) {
            this.endToEndUId = sessionStorage.getItem('E2E');
          this.enterpriseId = "pravalika.pogula";
            this.tabCode = "MW";
        }
        else {
            this.queryString = window.location.href.split('/');
            //check
            if (this.queryString.length > 4)
                this.tabCode = this.queryString[4].toUpperCase();
            else
                this.tabCode = this.queryString[3].toUpperCase();
            //this.endToEndUId = this.queryString[this.queryString.length - 1].toLowerCase();
            this.endToEndUId = "ea0d8121-266b-4a18-9b62-0f33953c060a";
        }
    }

    setLoadOpportuityRequest(LoadOpportunityRequest: any) {
        this.LoadOpportunityRequest = LoadOpportunityRequest;
        this.change.emit(this.LoadOpportunityRequest);
    }

    APtoAJHeatMap<T>(EndToEndUId: string, cmsUId: string, wsUId: string): Observable<T> {
        //SKM
        var url = this.baseURL + "ENS/APtoAJHeatMap?endToEndUId=" + EndToEndUId + "&cmsUId=" + cmsUId + "&wsUId=" + wsUId;
        // Need to pass AP API token: then call the below AP API to get the data        
        //return this.http.get<T>('https://mywizard-aiprojectmanagerapi.ciostage.accenture.com/Baseline/APtoAJHeatMap');
        //return this.http.get<T>('http://localhost:58779/Baseline/APtoAJHeatMap');
        return this.http.get<T>(this.baseURL + "ENS/APtoAJHeatMap?endToEndUId=" + EndToEndUId + "&cmsUId=" + cmsUId + "&wsUId=" + wsUId);
        //return this.http.get<T>(this.baseURL + "ENS/APtoAJHeatMap?endToEndUId=" + EndToEndUId);
    }

    GetProductList<T>(EndToEndUId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/ProductList/" + EndToEndUId);
    }

    GetAIAARAGStatus<T>(data: any): Observable<T> {
        return this.http.post<T>(this.baseURL + "/AIAARAGStatus", { data });
    }

    GetAOFAssessments<T>(EndToEndUId: string, CMSUId: string, WorkstreamUId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/AOFAssessments/" + EndToEndUId + "/" + CMSUId + "/" + WorkstreamUId);
    }

    GetMigrateAiaa<T>(EndToEndUId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/MigrateAiaa/" + EndToEndUId);
    }

    GetMigrationStatus<T>(EndToEndUId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/MigrationStatus?EndToEndUId=" + EndToEndUId);
    }

    GetUserAccessSettings<T>(EndToEndUId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/GetEndToEndAccessSettings?EndToEndUId=" + EndToEndUId);
    }

    UpdateDueDate<T>(OpportunityId: number, PlannedDate: string): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/AddPlannedDate/" + OpportunityId + "/" + PlannedDate);
    }

    UpdateDueDateAllOpportunities<T>(OpportunityId: number, PlannedDate: string): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/AddPlannedDateforAllOpportunities?OpportunityId=" + OpportunityId + "&PlannedDate=" + PlannedDate);
    }

    getReport<T>(Contracts: any): Observable<T> {
        return this.http.post<T>(this.baseURL + "HPDP/GetReport", Contracts);
    }

    getTicketVolume<T>(OpportunityId: number): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/GetUsage/" + OpportunityId);
    }

    GetAssetAttributeUsage<T>(OpportunityId: number, AssetId: number): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/GetAssetUsage/" + OpportunityId + "/" + AssetId);
    }

    saveAttributeUsageForTicketVolume<T>(data: any): Observable<T> {
        return this.http.post<T>(this.baseURL + "AJ/AddOrUpdateAttributeUsage", data);
    }

    saveValuesAndFTEforSavings<T>(data: any): Observable<T> {
        return this.http.post<T>(this.baseURL + "AJ/AddOrUpdateOpportunityValue", data);
    }

    UpdateCustomAssetOrderStatus<T>(assetId: number, automationPlannerId: string, assetName, assetType: string, orderStatus: string): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/UpdateCustomAssetOrderStatus?assetId=" + assetId + '&endToEndUId=' + automationPlannerId + '&assetName=' + assetName + '&assetType=' + assetType + '&orderStatus=' + orderStatus);
    }

    UpdateAssetStatus<T>(assetId: number, status: string): Observable<T> {
        return this.http.post<T>(this.baseURL + "AJ/UpdateOrderStatus", { assetId, status });
    }

    UpdateAssetCompletedStatus<T>(): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/UpdateAssetCompletedStatus");
    }

    UpdateOpportunityAssetStatus<T>(): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/UpdateOpportunityAssetStatus");
    }
    UpdateOppertunityStatus<T>(OpportunityId: string, Status: string): Observable<T> {
        return this.http.post<T>(this.baseURL + "AJ/UpdateOppertunityStatus", { OpportunityId, Status });
    }

    GetNotificationsList<T>(data: any): Observable<T> {
        return this.http.post<T>(this.baseURL + "Notifications/TileNotifications/", data);
    }

    GetOpportunityList<T>(data: any): Observable<T> {
        return this.http.post<T>(this.baseURL + "AJ/OpportunityList", data);
    }

    GetAllOpportunityList<T>(data: any): Observable<T> {
        return this.http.post<T>(this.baseURL + "AJ/AllOpportunityList", { data });
    }

    AddToCart<T>(ProductUId: string, EndToEndUId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + "MAS/AddProductToCart?ProductUId=" + ProductUId + "&EndToEndUId=" + EndToEndUId);
    }

    GetUsage<T>(opportunityId: number): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/GetUsage?OpportunityId=" + opportunityId);
    }

    GetSavingsValue<T>(opportunityId: number): Observable<T> {
        return this.http.get<T>(this.baseURL + "AJ/GetValue/" + opportunityId);
    }

    getReviewers<T>(): Observable<T> {
        return this.http.get<T>(this.baseURL + "HPDP/Reviewers");
    }

    getClients<T>(enterpriseId: string, clientId: string, engagementId: string, projectId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + 'HPDP/HPDP/ClientDetails?enterpriseId=' + enterpriseId + '&clientId=' + clientId + '&engagementId=' + engagementId + '&projectId=' + projectId);
    }

    getUserAccess<T>(enterpriseId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + 'HPDP/ClientUser?userId=' + enterpriseId);
    }

    getClientHierarchy<T>(enterpriseId: string, EndToEndUId: string, clientWMSId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + 'HPDP/GetClientHierarchy/' + enterpriseId + '/' + EndToEndUId + '/' + clientWMSId);
    }

    // scope selector start
    getWorkStreams<T>(EndToEndUId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + 'HPDP/GetWorkStreams/' + EndToEndUId);
    }

    getProjectAttributes<T>(EndToEndUId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + "HPDP/GetProjectAttributes/" + EndToEndUId);
    }
    // scope selector end

    getOGOUCSG<T>(enterpriseId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + 'HPDP/GetOGOUCSG/' + enterpriseId + '/');
    }

    GetOGOUCSGNP<T>(EndToEndUId: string): Observable<T> {
        return this.http.post<T>(this.baseURL + "HPDP/GetOGOUCSGNP", { EndToEndUId });
    }

    getAJUserAccess<T>(EndToEndUId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + 'AJ/GetUserAccess/' + EndToEndUId);
    }

    GetUsageScheduleInterval<T>(EndToEndUId: string): Observable<T> {
        return this.http.get<T>(this.baseURL + 'AJ/GetandCreateInterval/' + EndToEndUId);
    }

    SchedueleService<T>(UsageData: any): Observable<T> {
        return this.http.post<T>(this.baseURL + "AJ/RefreshUsageSchdule", { UsageData });
    }

    AddUsageServiceSchedule<T>(EndToEndUId: string, interval: string, StartDate: string): Observable<T> {
        var detail = { EndToEndUId: EndToEndUId, Interval: interval, StartDate: StartDate };
        return this.http.post<T>(this.baseURL + "AJ/AddUsageServiceSchedule", { detail });
    }

    UpdateAutomationJourney<T>(EndToEndUId: string, EnterpriseId: string, OpportunityId: any, PlanStatus: any): Observable<T> {
        //  var AJPlanModel = { EndToEndUId: EndToEndUId, EnterpriseId: enterpriseid, OpportunityId: opportunityId, PlanStatus: planStatus };
        return this.http.post<T>(this.baseURL + "AJ/UpdateAutomationJourney", { EndToEndUId, EnterpriseId, OpportunityId, PlanStatus });
    }

    GetSavingsDetails<T>(EndToEndUIds: string[], SelectedOG: string, SelectedOU: string, SelectedCSG: string): Observable<T> {
        var EndToEndUIdsData = {EnterpriseId :this.enterpriseId,EndToEndUIds: EndToEndUIds, OG: SelectedOG, OU: SelectedOU, CSG: SelectedCSG };
        return this.http.post<T>(this.baseURL + "HPDP/GetSavingsDetails",  EndToEndUIdsData);
    }

    getUseCaseImplementedDetails<T>(apCatergoryId: any) {
        return this.http.get<T>(this.baseURL + 'AJ/UseCasesImplemented/' + apCatergoryId);
    }

    //sample post method from gtr
    saveAssignedTicketCategory<T>(lstSaveCategoryResolution: any, UserId: string): Observable<T> {
        return this.http.post<T>(this.baseURL + "/TicketServiceMongo/SaveAssignedTicketCategory", { lstSaveCategoryResolution, UserId });
    }

    showSuccessMessage(message: string) {
        bootbox.alert({
            title: "<span style='color: green;'>Success..!!</span>",
            message: message,
            closeButton: false
        }).find('.btn-primary').removeClass('btn').css({ 'padding': '6px 12px', 'border-radius': '4px', 'border': '1px solid transparent' });
    }

    displayErrorMessage(err) {
        if (err.error != undefined && typeof (err.error) == "string")
            this.showErrorMessage(err.error);
        else if (err.message != undefined)
            this.showErrorMessage(err.message);
        else if (err.error != undefined && err.error.title != undefined)
            this.showErrorMessage(err.error.title);
        else if (err.error != undefined && err.error.text != undefined)
            this.showErrorMessage(err.error.text);
        else if (err.error != undefined && err.error.value != undefined)
            this.showErrorMessage(err.error.value);
    }

    showErrorMessage(message: string) {
        bootbox.alert({
            title: "<span style='color: red;'>Errors..!!</span>",
            message: message,
            closeButton: false
        }).find('.btn-primary').removeClass('btn').css({ 'padding': '6px 12px', 'border-radius': '4px', 'border': '1px solid transparent' });
    }

    showInfoMessage(message: string) {
        bootbox.alert({
            title: "<span style='color: white;'>Info..!!</span>",
            message: message,
            closeButton: false
        }).find('.btn-primary').removeClass('btn').css({ 'padding': '6px 12px', 'border-radius': '4px', 'border': '1px solid transparent' });
    }

    showConfidentialityMessage(message: string) {
        bootbox.dialog({
            title: "<span style='color: #008; font-size:20px; border-color: white'>Confidential Information – Reminder</span>",
            message: message,
            closeButton: false,
            buttons: {
                ok: {
                    label: 'I Understand',
                    className: 'centerbutton btn-primary'
                }
            }
        }).find('.modal-content').css({ 'width': '580px', 'margin-top': '150px' })
            .find('.modal-header').css({ 'background-color': 'white', 'border-color': 'white' })
            .find('.modal-body').css({ 'padding': '1.25rem !important' })
    }

    showConfirmMessage(message: string) {
        bootbox.confirm({
            message: message,
            callback: function (result) { return result; /* result is a boolean; true = OK, false = Cancel*/ }
        }).find('.btn-primary').removeClass('btn').css({ 'padding': '6px 12px', 'border-radius': '4px', 'border': '1px solid transparent' });
    }

    dummyObservable<T>(): Observable<any> {
        return observableOf(1);
    }

    ExcelReportDownload(data:any){
        return this.http.post(this.baseURL + 'ExcelExport/ExcelExport/' , data, {responseType: 'blob'});
    }

    GetAssetCount<T>(data:any): Observable<any>{
        return this.http.post<T>(this.baseURL + 'ExcelExport/GetAssetCount/', data)
    }

    GetContract<T>(contract:any): Observable<any>{
        return this.http.post<T>(this.baseURL + 'ExcelExport/GetContract/', contract)
    }

    GetData<T>(data:any): Observable<any>{
        return this.http.post<T>(this.baseURL + 'ExcelExport/GetData', {data})
    }

    AddComment<T>(opportunityId: any, comment:string): Observable<any>{
        //var detail = {OpportunityId:OpportunityId, comment:Comment}
        return this.http.post<T>(this.baseURL + 'AJ/AddComment', {opportunityId, comment})
    }
    
    GetComment<T>(opportunityId:any): Observable<any>{
        return this.http.get<T>(this.baseURL + 'AJ/GetComment/' + opportunityId)
    }
}

