export const environment = {
    production: false,
    MSALenabled: true,

    serviceUrl: 'https://mywizardajnextgenapi.ciostage.accenture.com/api/',
    //localAPIUrl: "http://localhost:18047/api/",
    localAPIUrl: "https://mywizardajnextgenapi.ciostage.accenture.com/api/",
    federationLogoutURL: 'https://federation-sts.accenture.com/adfs/ls/?wa=wsignout1.0',
    masHomeUrl: 'https://mywizardautostorenextgen.ciostage.accenture.com/',
    aofHomeUrl: 'https://mywizard-aof.ciostage.accenture.com/',
    aiaaHomeUrl: 'https://mywizardaiaa.ciostage.accenture.com/',
    apHomeUrl: 'https://mywizard-aiprojectmanager.ciostage.accenture.com/opportunity/',
    apBaseLineUrl: 'https://mywizard-aiprojectmanager.ciostage.accenture.com/baseline/',
    hpdpHomeUrl: 'https://mywizardnextgen.ciostage.accenture.com/MyWizard/Back/',
    termsOfUseUrl: 'https://mywizardnextgen.ciostage.accenture.com/Views/TermsOfUse.html',
    webClientID: '24fef1aa-598b-47e3-a09f-d1c3cff3c66e',
    apiClientID: '4583f7e2-0241-4ab6-8e2b-90d9e8dc6762',
    tenantID: 'e0793d39-0939-496d-b129-198edd916feb',
    authority: 'https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb/',
    azureRedirectURL: 'https://mywizardajnextgen.ciostage.accenture.com/',
    apBluePrintURL:'https://mywizard-aiprojectmanager.ciostage.accenture.com/home',
    apTreasuryURL:'https://mywizard-aiprojectmanager.ciostage.accenture.com/opportunityTreasury',
};
