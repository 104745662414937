<!-- <div class="standard-line-chart-container">
    <div class="heading-section">
        <span id="heading">Line Chart</span>
    </div>
    <div id="standard-line-chart-section" class="standard-line-chart-section">
    </div>
    <div>
        <app-graph-carousel (nextPageEmit)="nextPage($event)" (prevPageEmit)="prevPage($event)"
            [countPerPage]="barnumber" [graphData]="data" [curPage]="page">
        </app-graph-carousel>
    </div>
    <div class="center_align">
        <ul class="info-values">
            <li class="val category1">Category1</li>
            <li class="val category2">Category2<li>
            <li class="val category3">Category3</li>
        </ul>
    </div>
</div> -->
<div>
    <div id="fte-line-chart-section" class="fte-line-chart-section"></div>
</div>