<!--<div class="bullet-chart-container">
     <div class="heading-section">
      <span id="heading">Bullet Chart</span>
    </div> -->
    <!-- <div class="sub-heading-section">
        <span id="sub-heading">Savings</span>
    </div> 
    
</div>-->
<div id="bullet-chart-section" class="bullet-chart-section" style="position: absolute;margin-top: -0.5em;">
</div>