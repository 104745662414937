import { Component, HostListener, OnInit } from '@angular/core';
import { common } from 'src/app/common/common';
import * as d3 from 'd3';
import { AJWebService } from 'src/services/ajWeb.service';

@Component({
  selector: 'mri-cluster-bar-chart-two',
  templateUrl: './useCasePopUp-cluster-bar-chart-two.component.html',
  styleUrls: ['./useCasePopUp-cluster-bar-chart-two.component.scss']
})
export class ClusterBarChartTwoComponent implements OnInit {

  data: { month: string; color: string; values: { value: number; category: string; }[] }[];
  svg: any;
  viewData = [];
  // barnumber = 3;
  // page = 1;
  width: any;
  height: any;
  margin = { top: 20, bottom: 80, left: 70, right: 20 };
  color = ["#9657D5", "#B95FD2"];
  legendKeys = ["Planned", "Completed"];
  max: any;
  chart: any;
  slice: any;
  slices: any;
  xAxisLabel = "No. of Automation Usecases";
  yAxisLabel = "Queues";
  legendContainer: any;
  selector = 'cluster-bar-chart-section';
  maxValue = 0;

  constructor(public ajWebService: AJWebService) { }

  ngOnInit() {
    this.ajWebService.showUseCasesImplementationChartInPopUp.subscribe(opportunityQueues => {
      let opportunityQueueData = this.ajWebService.opportunityQueues;
      if (opportunityQueueData != undefined && opportunityQueueData.length > 0) {
        let finalData = [];
        for (var i = 0; i < opportunityQueueData.length; i++) {
          if (i < 4 || opportunityQueueData[i]["queueName"] == "Others") {
            finalData.push({
              "month": opportunityQueueData[i]["queueName"],
              "color": "#A260B7",
              "values": [
                {
                  "value": opportunityQueueData[i]["Complete"].length + opportunityQueueData[i]["InProgress"].length,
                  "category": "Category 1"
                },
                {
                  "value": opportunityQueueData[i]["Complete"].length,
                  "category": "Category 2"
                }
              ],
              "highestValue": opportunityQueueData[i]["Complete"].length + opportunityQueueData[i]["InProgress"].length
            })

            if (opportunityQueueData[i]["Complete"].length + opportunityQueueData[i]["InProgress"].length > this.maxValue)
              this.maxValue = opportunityQueueData[i]["Complete"].length + opportunityQueueData[i]["InProgress"].length;
          }
        }
        finalData = finalData.sort((a, b) => a.highestValue > b.highestValue ? 1 : -1);

        finalData.reverse();
        // for (var i = 0; i < finalData.length; i++) {
        //   finalData[i]["recordNo"] = i;
        //   finalData[i].values[0]["recordNo"] = i;
        //   finalData[i].values[1]["recordNo"] = i;
        // }
        // finalData = finalData.sort((a, b) => a.recordNo > b.recordNo ? -1 : 1)
        // let finalDatatry = []
        // for(var j = 0; j<finalData.length; j++){
        //   if(finalData[j].month.length>10)
        //     finalDatatry[j].push(month.substring(0,5)+'...')
        //   else
        //   return finalData[j].month
        // }
        this.data = finalData;
        this.drawGraph();
      }
    }, error => {
      this.ajWebService.showErrorMessage(error);
    })
  }

   ngAfterViewChecked() {
     if (this.viewData != undefined)
       if (this.viewData.length > 0)
         this.drawGraph();
   }

  drawGraph() {
    this.prepareData();
    this.renderSVGContainer();
    this.renderChartContainer();
    this.renderXAxis();
    this.renderGrid();
    this.renderYAxis();
    this.renderShadowBars();
    this.renderBars();
    this.renderBarText();
    this.renderXAxisLabel();
    this.renderYAxisLabel();
    this.renderLegend();
  }

  prepareData() {
    //this.viewData = this.data.slice((this.page-1)*this.barnumber,this.page*this.barnumber);



    let result = [];
    this.data.forEach(elem => {
      let match = result.find(r => r.month === elem.month);
      if (match) {
        Object.assign(match, elem);
      } else {
        result.push(elem);
      }
    });
    this.viewData = result;
  }

  renderSVGContainer() {
    let elem = <HTMLElement>document.getElementsByClassName(this.selector)[0];
    elem.innerHTML = "";
    this.svg = common.getSVG(this.selector, elem.parentElement.parentElement.offsetWidth, elem.parentElement.parentElement.offsetHeight);
    this.width = elem.offsetWidth - this.margin.left - this.margin.right;
    this.height = elem.offsetHeight - this.margin.top - this.margin.bottom;
  }

  xScale() {
    return d3.scaleLinear()
      .domain([0, 1.2 * this.maxValue]).nice()
      .range([0, this.width]);
  }

  y0Scale() {
    return d3.scaleBand()
      .range([this.height, 0])
      .domain(this.viewData.map((s) => s.month))
      .padding(0.1);
  }

  y1Scale() {
    return d3.scaleBand()
      .domain(this.viewData[0].values.map(d => d.category))
      .rangeRound([0, this.y0Scale().bandwidth()])
      .padding(0.1);
  }

  makeXLines() {
    return d3.axisTop(this.xScale())
      .scale(this.xScale()).ticks(10)
      .tickSize(36).tickPadding(10);
  }

  renderChartContainer() {
    this.chart = this.svg.append('g')
      .attr('class', 'svgstyle')
      .attr('transform', 'translate(' + [this.margin.left + 40, this.margin.top + 35] + ')');
  }

  renderXAxis() {
    this.chart.append('g')
      .attr('transform', `translate(0 , ${this.height})`)
      .call(d3.axisBottom(this.xScale()).tickSizeOuter(0))
      .call(d => {
        d.selectAll("path").attr("stroke", "#E4E4E4").attr("fill", "#8c8c8c");
        d.selectAll("line").remove();
        d.selectAll("text").remove();
      });
  }

  renderGrid() {
    const grid = this.chart.append('g')
      .attr('class', 'grid')
      //.call(this.makeXLines().tickSize(-this.height, 0, 0))
      .call(this.makeXLines().tickSize(-this.height))
      .call(d => d.select("path").remove())

    grid.selectAll("line")
      .attr("stroke", "#E4E4E4")
    grid.selectAll("text")
      .attr("font-family", "sans-serif")
      .attr("font-size", "10px")
      .attr("fill", "#8c8c8c");
  }

  renderYAxis() {
    this.chart.append('g')
      .attr('class', 'axis y-axis')
      .call(d3.axisLeft(this.y0Scale()))
      .call(d => d.select(".domain").remove())
      .call(d => d.selectAll("line").attr("stroke-opacity", 0).attr("fill", "#8c8c8c"))
      .call(d => {
        d.selectAll("path").attr("stroke", "#E4E4E4").attr("fill", "#8c8c8c");
      })
  }

  renderSlice() {
    return this.slice = this.svg.selectAll(".slice")
      .data(this.viewData)
      .enter().append("g")
      .attr("class", "g")
      .attr("id", "groups")
      .attr("transform", d => "translate(" + [this.margin.left + 40] + "," + (this.y0Scale()(d.month) + 54) + ")")
  }

  renderSlices() {
    return this.slices = this.renderSlice().selectAll("path")
      .data(d => d.values)
      .enter();
  }

  renderBars() {
    const xscale_bw = 26;
    const yscale_bw = this.y1Scale().bandwidth(),
      rx = xscale_bw / 3,
      ry = xscale_bw / 3;

    this.renderSlices()
      .append('path')
      .attr('class', 'bar')
      .attr("d", d => {
        let rcWidth = (d.value < (this.max * (10 / 100))) ? this.xScale()(d.value) : this.xScale()(d.value) - rx;
        if (d.value > 0) {
          return `
            M${0},${this.y1Scale()(d.category) + this.y1Scale().bandwidth() / 2 - ry}
            h${rcWidth}
            a${rx},${ry} 0 0 1 ${rx},${ry}
            v 0.1
            a${rx},${ry} 0 0 1 ${-rx},${ry}
            h${-rcWidth}Z
          `}
      })
      .attr('fill', (d, i) => this.color[i]);
  }

  renderShadowBars() {
    const xscale_bw = 24;
    const yscale_bw = this.y1Scale().bandwidth(),
      rx = xscale_bw / 3,
      ry = xscale_bw / 3;

    this.renderSlices()
      .append('path')
      .attr('class', 'greyround-bar')
      .attr("d", d => `
        M${0},${this.y1Scale()(d.category) + this.y1Scale().bandwidth() / 2 - ry}
        h${this.width - 10}
        a${rx},${ry} 0 0 1 ${rx},${ry}
        v 0.1
        a${rx},${ry} 0 0 1 ${-rx},${ry}
        h${-this.width + 10} Z
      `)
      .attr('fill', '#EBEFF4');
  }

  renderBarText() {
    if (this.viewData.length == 1)
      this.renderSlices()
        .append("text")
        .attr('transform', (d, i) => {
          if (d.category == 'Category 1') {
            return `translate(${this.xScale()(d.value / 2) - 2} ${80})`
          } else if (d.category == 'Category 2') {
            return `translate(${this.xScale()(d.value / 2) - 2} ${215})`
          }
        })
        .attr("text-anchor", "middle")
        .attr("font-family", "sans-serif")
        .attr("font-size", "12px")
        .attr('fill', '#f2f2f2')
        .text(d => {
          if (d.value > 0)
            return `${d.value}`
        })
    else if (this.viewData.length == 2)
      this.renderSlices()
        .append("text")
        .attr('transform', (d, i) => {
          if (d.category == 'Category 1') {
            return `translate(${this.xScale()(d.value / 2) - 2} ${45})`
          } else if (d.category == 'Category 2') {
            return `translate(${this.xScale()(d.value / 2) - 2} ${115})`
          }
        })
        .attr("text-anchor", "middle")
        .attr("font-family", "sans-serif")
        .attr("font-size", "12px")
        .attr('fill', '#f2f2f2')
        .text(d => {
          if (d.value > 0)
            return `${d.value}`
        })
        else if (this.viewData.length == 3)
      this.renderSlices()
        .append("text")
        .attr('transform', (d, i) => {
          if (d.category == 'Category 1') {
            return `translate(${this.xScale()(d.value / 2) - 2} ${30})`
          } else if (d.category == 'Category 2') {
            return `translate(${this.xScale()(d.value / 2) - 2} ${80})`
          }
        })
        .attr("text-anchor", "middle")
        .attr("font-family", "sans-serif")
        .attr("font-size", "12px")
        .attr('fill', '#f2f2f2')
        .text(d => {
          if (d.value > 0)
            return `${d.value}`
        })
    else if (this.viewData.length == 5)
      this.renderSlices()
        .append("text")
        .attr('transform', (d, i) => {
          if (d.category == 'Category 1') {
            return `translate(${this.xScale()(d.value / 2) - 2} ${25})`
          } else if (d.category == 'Category 2') {
            return `translate(${this.xScale()(d.value / 2) - 2} ${60})`
          }
        })
        .attr("text-anchor", "middle")
        .attr("font-family", "sans-serif")
        .attr("font-size", "12px")
        .attr('fill', '#f2f2f2')
        .text(d => {
          if (d.value > 0)
            return `${d.value}`
        })

    else
      this.renderSlices()
        .append("text")
        .attr('transform', (d, i) => {
          if (d.category == 'Category 1') {
            return `translate(${this.xScale()(d.value / 2) - 2} ${25})`
          } else if (d.category == 'Category 2') {
            return `translate(${this.xScale()(d.value / 2) - 2} ${60})`
          }
        })
        .attr("text-anchor", "middle")
        .attr("font-family", "sans-serif")
        .attr("font-size", "12px")
        .attr('fill', '#f2f2f2')
        .text(d => {
          if (d.value > 0)
            return `${d.value}`
        })
  }

  renderXAxisLabel() {
    this.chart.append("text")
      .attr("transform",
        "translate(" + ((this.width / 2)) + " ," +
        (this.height + this.margin.top + 25) + ")")
      .attr("text-anchor", "middle")
      .attr("font-family", "sans-serif")
      .attr("font-size", "12px")
      .text(this.xAxisLabel);
  }

  renderYAxisLabel() {
    this.chart.append("text")
      .attr("y", 0 - this.margin.left + 20)
      .attr("x", 0 - (this.height / 2) + 135)
      .attr("dy", "1em")
      .attr("text-anchor", "middle")
      .attr("font-weight", "bold")
      .attr("font-family", "sans-serif")
      .attr("font-size", "12px")
      .text(this.yAxisLabel);
  }

  renderLegendContainer() {
    //debugger;
    return this.legendContainer = this.svg.append("g")
      .attr("id", "legend-container")
  }

  renderLegendCircle(container) {
    container.append("circle")
      .attr("cx", (d, i) => 60 + i * 60)
      .attr("cy", 5)
      .attr("r", 5)
      .style("fill", (d, i) => this.color[i])
  }

  renderLegendText(container) {
    container.append('text')
      .attr('font-size', '10px')
      .attr("font-family", "sans-serif")
      .attr("fill", "#BCB3C7")
      .attr('text-anchor', 'start')
      .attr("alignment-baseline", "after-edge")
      .attr("x", (d, i) => 70 + i * 60)
      .attr('y', '10')
      .text((d: any) => d);
  }

  transformLegends(container, self, nodeWidth) {
    let offset = 0;
    container.attr('transform', function (d, i) {
      let x = offset;
      offset += nodeWidth(this) - 20;
      return `translate(${x},${self.height + 10})`;
    });
  }

  // transformLegendContainer(container, self, nodeWidth) {
  //   container.attr('transform', function () {
  //     return `translate(${((self.width/2) - nodeWidth(this)) + 100},${-self.height + 690})`
  //   });
  // }

  transformLegendContainer(container, self, nodeWidth) {
    container.attr('transform', function () {
      return `translate(${((self.width / 2) - nodeWidth(this)) + 80},${-self.height + 410})`
    });
  }

  renderLegend() {
    const nodeWidth = (d) => d.getBBox().width,
      lgContainer = this.svg.append("g")
        .attr("id", "legend-container"),
      lg = lgContainer.selectAll('g')
        .data(this.legendKeys)
        .join('g');

    this.renderLegendCircle(lg);
    this.renderLegendText(lg);
    this.transformLegends(lg, this, nodeWidth);
    this.transformLegendContainer(lgContainer, this, nodeWidth)
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   common.clearSVG(this.selector);
  //   this.drawGraph();
  // }
}

