import { Component, OnInit, HostListener, Input } from '@angular/core';
import * as d3 from 'd3';
import { common } from 'src/app/common/common';
import { AJWebService } from 'src/services/ajWeb.service';

@Component({
  selector: 'fte-line-chart',
  templateUrl: './fte-line-chart.component.html',
  styleUrls: ['./fte-line-chart.component.scss']
})
export class FTELineChartComponent implements OnInit {

  data: any;
  viewData = [];
  barnumber = 7;
  page = 1;
  margin = { top: 20, bottom: 180, left: 70, right: 50 };
  width: any;
  height: any;
  xScale: any;
  yScale: any;
  selector = 'fte-line-chart-section';
  color = "#00F3FF";
  xAxisText = "Months";
  yAxisText = "Value(FTE)";
  xscale_bw = 24;
  circle_stroke_width = 3;
  line_stroke_width = 2;
  circle_radius = 4;
  chart: any;
  svg: any;

  @Input() monthsList: any;
  @Input() yearsList: any;
  @Input() currentDate: any;
  @Input() opportunityId: any;
  public highestValue: number = 0;

  public fteValues: any[];

  constructor(public ajWebService: AJWebService) { }

  ngOnInit() {
    //common.clearSVG(this.selector);
    this.ajWebService.displaySavingsGraphPopUp.subscribe(opportunityId => {
      this.opportunityId = opportunityId;
      this.getData();
    }, error => {
      this.ajWebService.showErrorMessage(error);
    })
  }

  drawGraph() {
    //this.viewData = this.transformData();
    this.viewData = this.data;
    let elem = <HTMLElement>document.getElementsByClassName(this.selector)[0];
    elem.innerHTML = "";
    this.svg = this.getSVG(700, 300);
    this.width = 600; //elem.offsetWidth - this.margin.left - this.margin.right;
    this.height = 180; //450 - this.margin.top - this.margin.bottom;
    this.xScale = this.getXScale();
    this.yScale = this.getYScale();
    this.renderXAxis();
    this.renderYAxis();
    this.renderYAxisText();
    this.renderXAxisText();
    this.renderLine();
    this.renderPointCircles();
  }

  renderPointCircles() {
    const circleGroups = this.chart.selectAll()
      .data(this.viewData)
      .enter()
      .append('g');

    const circle_category2 = circleGroups.append("circle")
      .attr("cx", d => this.xScale(d.created_date))
      .attr("cy", d => this.yScale(d.line_val))
      .attr("r", this.circle_radius)
      .attr("fill", this.color)
      .attr("stroke-width", this.circle_stroke_width)
      .append('svg:title')
      .text((d) => d["line_val"]);
  }

  renderLine() {
    const lineGroups = this.chart.selectAll()
      .data(this.viewData)
      .enter()
      .append('g');

    let line = d3.line()
      .x((d: any) => this.xScale(d.created_date))
      .y((d: any) => this.yScale(d.line_val));
     //.curve(d3.curveCatmullRom.alpha(1));

    // Category line STARTS
    lineGroups.append("path")
      .attr("fill", "none")
      .attr("stroke-width", this.line_stroke_width)
      .attr("opacity", "1")
      .attr("stroke", this.color)
      .attr("d", line(this.viewData));
  }

  renderXAxisText() {
    this.chart.append("text")
      .attr("transform",
        "translate(" + (this.width / 2) + " ," +
        (this.height + this.margin.top + 50) + ")")
      .attr("text-anchor", "middle")
      .attr("font-family", "sans-serif Medium")
      .attr("font-size", "12px")
      .text(this.xAxisText);
  }

  renderYAxisText() {
    this.chart.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - this.margin.left)
      .attr("x", 0 - (this.height / 2))
      .attr("dy", "1em")
      .attr("text-anchor", "middle")
      .attr("font-family", "sans-serif Medium")
      .attr("font-size", "12px")
      .text(this.yAxisText);
  }

  renderXAxis() {
    this.chart = this.svg.append('g')
      .attr('class', 'svgstyle')
      .attr('transform', 'translate(' + [this.margin.left, this.margin.top] + ')');

    this.chart.append('g')
      .attr('transform', `translate(0, ${this.height})`)
      .call(d3.axisBottom(this.xScale).tickSizeOuter(0))
      .call(d => {
        d.selectAll("path").remove();
        d.selectAll("line").remove();
        d.selectAll("text")
          .each(this.insertLinebreak)
          .attr("font-family", "sans-serif")
          .attr("font-size", "10px");
      });
  }

  renderYAxis() {

    this.chart.append('g')
      .attr('transform', `translate(${this.width}, 0)`)
      .attr('class', 'y-axis')
      .call(d3.axisRight(this.yScale).tickPadding(10).tickSizeOuter(0))
      .call(d => {
        d.selectAll("path")
          .attr("stroke", "#E4E4E4")
        d.selectAll("line").remove();
        d.selectAll("text").remove();
      });

    const makeYLines = () => d3.axisLeft(this.yScale).tickPadding(10)
      .ticks(5);

    const grid = this.chart.append('g')
      .attr('class', 'grid')
      .call(makeYLines().tickSize(-this.width))
      .call(d => d.select(".domain").attr("stroke", "#E4E4E4"));

    grid.selectAll("line")
      .attr("stroke", "#E4E4E4");

    grid.selectAll("text")
      .attr("font-family", "sans-serif")
      .attr("font-size", "10px");

  }

  getYScale() {
    return d3.scaleLinear()
      .rangeRound([this.height, 0])
      .domain([0, Math.round(Math.max(...this.data.map(o => o.line_val), 0) * 1.2)]).nice();
  }

  getXScale() {
    return d3.scalePoint()
      .range([0, this.width])
      .domain(this.viewData.map((d) => d.created_date))
      .padding(0);
  }

  getSVG(width: number, height: number) {
    return common.getSVG(this.selector, width, height);
  }

  transformData(): any[] {
    return this.data.slice((this.page - 1) * this.barnumber, this.page * this.barnumber);
  }

  getData(): any {
    this.ajWebService.GetSavingsValue(this.opportunityId).subscribe(savingsData => {
      if (savingsData != undefined) {
        this.fteValues = savingsData["AttributeValue"].find(x => x.AttributeName == "FTE")["Values"];

        let monthYear: String = '';
        let finalData = [];
        for (var i = 1; i <= 12; i++) {
          monthYear = this.monthsList[this.currentDate.getMonth() + i] != undefined ? this.monthsList[this.currentDate.getMonth() + i] + this.yearsList[0] : this.monthsList[this.currentDate.getMonth() + i - 12] + this.yearsList[1];
          finalData.push({
            "created_date": monthYear.substr(0, 3) + " " + monthYear.substr(3, 2),
            "line_val": this.fteValues.find(x => x.Month == monthYear.substr(0, 3) + " " + monthYear.substr(3, 2)) != undefined ? this.fteValues.find(x => x.Month == monthYear.substr(0, 3) + " " + monthYear.substr(3, 2)).Value : 0
          })
        }
        this.data = finalData;
        this.drawGraph();
      }
    },
      error => {
        console.log(error)
      })
  }

  insertLinebreak(d) {
    let labels = d3.select(this.selector);
    let words = d;
    labels.text('');

    let index = words.indexOf(' ', words.indexOf(' '))
    let title = words.substr(0, index)
    let subtitle = words.substr(index + 1)

    let tspantitle = labels.append('tspan').text(title)
    let tspansubtitle = labels.append('tspan').text(subtitle)
    tspantitle
      .attr('x', 0)
      .attr('dy', '15')
    tspansubtitle
      .attr('x', 0)
      .attr('dy', '16')
  };

  nextPage(pageCount: number) {
    this.page = pageCount;
    this.viewData = this.transformData();
    this.drawGraph();
  }

  prevPage(pageCount: number) {
    this.page = pageCount;
    this.viewData = this.transformData();
    this.drawGraph();
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   common.clearSVG(this.selector);
  //   this.drawGraph();
  // }
}
