import { Component, HostListener, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { common } from 'src/app/common/common';
import { AJWebService } from 'src/services/ajWeb.service';

@Component({
  selector: 'app-donut-chart-ticket',
  templateUrl: './donut-chart-ticket.component.html',
  styleUrls: ['./donut-chart-ticket.component.scss']
})

export class DonutChartTicketComponent implements OnInit {
  dataset: any;
  @Input() chartIdSelector: string;
  @Input() opportunityId: number;

  public totalOccurencesCount: number;
  public fullyAutomatedCount: number;
  public partiallyAutomatedCount: number;
  public notAutomatedCount: number;

  width = 450;
  height = 450;
  margin = 30;
  radius: any;
  svg: any;
  labelValue = 'Total Resources';
  color = ['#67B7DC', '#DC67CE', '#DCDC67'];  //  automated fully,  automated partially, not automated
  constructor(public ajWebService: AJWebService) { }

  ngOnInit() {
    this.opportunityId = +this.chartIdSelector.substring(this.chartIdSelector.lastIndexOf('-') + 1);
    this.displayGraph();
    this.ajWebService.ticketVolumeDataUpdated.subscribe(queueIdAndopportunityId => {
      this.chartIdSelector = queueIdAndopportunityId;
      this.opportunityId = +this.chartIdSelector.substring(this.chartIdSelector.lastIndexOf('-') + 1);
      this.displayGraph();
    }, error => {
      this.ajWebService.showErrorMessage(error);
    })
  }

  displayGraph() {
    common.clearSVG(this.chartIdSelector);
    this.width = 100;
    this.height = 100;
    this.getData();
  }

  getData() {
    this.ajWebService.getTicketVolume(this.opportunityId).subscribe(data => {
      if (data != undefined) {
        this.totalOccurencesCount = data["AttributeUsage"].find(x => x.AttributeName == "Total Occurrences")["UsageValues"].map(item => item.Value).reduce((prev, next) => prev + next);
        this.fullyAutomatedCount = data["AttributeUsage"].find(x => x.AttributeName == "Automated Fully")["UsageValues"].map(item => item.Value).reduce((prev, next) => prev + next);
        this.partiallyAutomatedCount = data["AttributeUsage"].find(x => x.AttributeName == "Automated Partially")["UsageValues"].map(item => item.Value).reduce((prev, next) => prev + next);
        this.notAutomatedCount = this.totalOccurencesCount - this.fullyAutomatedCount - this.partiallyAutomatedCount;
        this.dataset = [
          { count: this.fullyAutomatedCount, label: 'Automated Fully' },
          { count: this.partiallyAutomatedCount, label: 'Automated Partially' },
          { count: this.notAutomatedCount, label: 'Not Automated' },
        ];
        this.drawGraph();
      }
    },
      error => {
        this.ajWebService.showInfoMessage(error.toString());
      })
  }

  drawGraph() {
    //this.radius = (Math.min(this.width, this.height) / 2) - this.margin;
    this.radius = 30;
    this.svg = this.getSVG();
    this.renderPolyLineCircles();
    this.renderCenterTotalCountText();
    //this.renderCenterLable();
    this.renderLegends();

    let list = document.getElementsByClassName("ticketVolume");
    if (list.length > 0)
      for (var i = 0; i < list.length; i++)
        if (i != 0)
          list.item(i).remove();
  }

  renderLegends() {
    let nodeWidth = (d) => d.getBBox().width;

    // adding outer g element
    const legend1And2 = this.svg.append('g')
      .attr('transform', 'translate(0,0)');
    const legend3 = this.svg.append('g')
      .attr('transform', 'translate(0,0)');

    // adding individual g elements for each legend
    const g1And2 = legend1And2.selectAll('g')
      .data(this.dataset)
      .enter()
      .append('g')
    

    // adding circles for legends
    let legendRadius1And2 = 5;
    g1And2.append('circle')
      .attr('fill', (d, i) => this.color[i])
      .attr('r', legendRadius1And2)
      .attr('id', (d, i) => "legend1And2Circle-" + i);
    
    // adding texts for legends
    g1And2.append('text')
      .attr("font-family", "sans-serif")
      .attr("font-size", "10px")
      .attr('fill', '#000000')
      .attr("text-align", "centre")
      .attr("x", legendRadius1And2 + 2)
      .attr("dy", "0.3em")
      .text(d => d.label)
      .attr('id', (d, i) => "legend1And2Text-" + i);
   
    let offset1And2 = -15;
    let height1And2 = this.height - 10;
    g1And2.attr('transform', function (d, i) {
      let y = offset1And2;
      offset1And2 += 10;
      return `translate(${65},${y + offset1And2})`;
    });
   
  }

  renderCenterLable() {
    this.svg.append('text')
      .attr('font-family', 'sans-serif Medium')
      .attr('font-size', '9px')
      .attr('dy', '2em')
      .attr('fill', '#B0B0B0')
      .attr('text-anchor', 'middle')
      .text(this.labelValue);
  }

  renderPolyLineCircles() {
    const mainArc = this.getMainArc();
    // Another arc that won't be drawn. Just for labels positioning
    let line_length = this.radius + 30,
      arc_outer_line = d3.arc()
        .innerRadius(line_length)
        .outerRadius(line_length);

    let arc_outer_value = d3.arc()
      .innerRadius(line_length + 18)
      .outerRadius(line_length + 18);

    const pie = d3.pie()
      .value((d: any) => d.count)
      .sort(null);

    const paths = this.svg.selectAll('g')
      .data(pie(this.dataset))
      .enter();

    // Add the polylines between chart and labels:
    // paths.append('polyline')
    //   .attr('stroke', 'black')
    //   .style('fill', 'none')
    //   .attr('stroke-width', 1)
    //   .attr('points', (d) => {
    //     const posA = mainArc.centroid(d), posB = arc_outer_line.centroid(d);
    //     return [posA, posB];
    //   });

    // Add circle to the polyline
    // paths.append('circle')
    //   .attr('r', 3)
    //   .attr('transform', d => 'translate(' + arc_outer_line.centroid(d) + ')')
    //   .attr('fill', (d, i) => this.color[i]);

    // Add values to the charts
    // paths.append('text')
    //   .attr('transform', d => 'translate(' + arc_outer_value.centroid(d) + ')')
    //   .attr('font-family', 'sans-serif')
    //   .attr('font-size', '12px')
    //   .attr('fill', '#B0B0B0')
    //   .attr('dy', '.35em')
    //   .attr('text-anchor', 'middle')
    //   .text(d => d.value + '%');

    paths.append('path')
      .attr('d', mainArc)
      .attr('fill', (d, i) => this.color[i])
      .append('svg:title')
      .text(d =>
        Math.round(d.value)
      );
  }

  renderCenterTotalCountText() {
    this.svg.append('text')
      .attr('font-family', 'sans-serif Black')
      .attr('font-size', '15px')
      .attr('dy', '0.25em')
      .attr('fill', '#000000')
      .attr('text-anchor', 'middle')
      .text(this.totalOccurencesCount)
      .append('svg:title')
      .text(d =>
        "Total Occurences"
      );
  }

  getMainArc() {
    return d3.arc()
      .innerRadius(d => this.radius * 0.7)
      .outerRadius(d => this.radius - 0.2)
      .padAngle(0.025);
  }

  getSVG() {
    return common.getSVG(this.chartIdSelector, this.width + 200, this.height)
      .attr('class', 'ticketVolume')
      .append('g')
      .attr('transform', 'translate(' + (this.width - 60) +
        ',' + (this.height / 2.5) + ')');
  }
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   if (event) {
  //     common.clearSVG(this.chartIdSelector);
  //     this.drawGraph();
  //   }
  // }
}
