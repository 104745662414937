import * as d3 from 'd3';

class Common {
    constructor() {}

    public addAttributes(attrs): any {
        const attributes = [];
        Object.getOwnPropertyNames(attrs).map((property) => {
            const attr = {name: property, value : attrs[property]};
            attributes.push(attr);
        });
        return attributes;
    }

    public getSVG(selector, width, height, isSelectorClass?) {
        if (isSelectorClass) {
             return d3.select(`.${selector}`).append('svg')
            .attr('id', 'app-svg')
            .attr('class', 'svg-style')
            .attr('width', width)
            .attr('height', height)
            .attr('style','overflow:inherit')
            .attr('preserveAspectRatio', 'xMidYMid meet');
        } else {
            return  d3.select(`#${selector}`).append('svg')
            .attr('id', 'app-svg')
            .attr('class', 'svg-style')
            .attr('width', width)
            .attr('height', height)
            .attr('style','overflow:inherit')
            .attr('preserveAspectRatio', 'xMidYMid meet');
        }
    }

    public clearSVG(selector?) {
        //debugger;
        if (selector) {
            d3.select(`#${selector}`).select('#app-svg').remove();
        } else {
            d3.select('#app-svg').remove();
        }
    }

    public clearLegendSVG(selector) {
        if (selector) {
            d3.select(`#${selector}`).select('#app-legend-svg').remove(); 
        } else {
            d3.select('#app-legend-svg').remove();
        }
    }
}
const common =  new Common();
export {common};
