

<header class="myw-app-header myw-container d-flex">

  <div class="myw-brand d-flex align-items-end">

    <img src="../assets/images/logo.svg" alt="accenture" />

    <span><img src="../assets/images/mywiz.svg" alt="myWizard"></span>

  </div>

  <div class="pull-right user-wrap" style="padding-left: 850px;padding-top: 20px">



    <span></span>

    <span>

      <!-- <img src="../assets/images/caret-down.svg" alt="accenture" /> -->

      <p><b>{{ajWebService.enterpriseId}}</b></p>

    </span>

    <!-- <p style="margin: -17px 1100px;">Welcome, <b>{{ajWebService.enterpriseId}}</b></p> -->

  </div>

  <!-- <button _ngcontent-tdi-c93="" type="button" class="btn btn-primary" (click)="logout()" style="margin-left: 12px;">Logout</button>   -->



</header>

<!-- topbar -->
<div class="myw-topbar">
  <div class="myw-container d-flex flex-wrap">
    <span style="font-size: 1.125rem;
    font-weight: 600;
    padding: .5rem 0;
    margin-right: 1rem;
    color: #fff;">Automation Value Portfolio Report</span>
  </div>
</div>


<div class="d-flex flex-nowrap align-items-center myw-navbar-wrap">
  <div class="myw-navbar-inner">
      <nav class="nav myw-nav">
          <p  class="nav-item nav-link active"></p>
          <p class="nav-item nav-link"></p>
      </nav>
  </div>
</div>


<!-- /topbar -->
<div class="myw-container">
<div class="row align-items-center">
<div class="col-xl-3 col-lg-4 col-6">
  <div class="form-group">
      <label class="form-label myw-required">Primary Operating Group</label>
      <select class="form-control" [(ngModel)]="SelectedOG" (change)="OGbehaviour(SelectedOG)"
          >
          <option  [ngValue]=null  disabled selected>--All--</option>
          <option *ngFor="let item of OperatingGroup" value={{item}}>{{item}}</option>
      </select>
  </div>
</div>
<div class="col-xl-3 col-lg-4 col-6">
  <div class="form-group">
      <label class="form-label myw-required">Primary Operating Unit</label>
      <select class="form-control" [(ngModel)]="SelectedOU" (change)="OUbehaviour(SelectedOU)"
          >
          <option [ngValue]=null  disabled selected>--All--</option>
          <option *ngFor="let item of OperatingUnit" value={{item}}>{{item}}</option>
      </select>
  </div>
</div>
<div class="col-xl-3 col-lg-4 col-6">
  <div class="form-group">
      <label class="form-label myw-required">Primary Client Service Group</label>
      <select class="form-control" [(ngModel)]="SelectedCSG" (change)="CSGbehaviour(SelectedCSG)"          >
        <option [ngValue]=null  disabled selected>--All--</option>
          <option *ngFor="let item of ClientServiceGroup" value={{item}}>{{item}}</option>
      </select>
  </div>
</div>
</div>

<div class="d-flex flex-nowrap align-items-center myw-navbar-wrap">
  <div class="myw-navbar-inner">
      <nav class="nav myw-nav">
          <p  class="nav-item nav-link"></p>
          <p class="nav-item nav-link"><b>Graph is temporarily unavailable.</b></p>
          <p  class="nav-item nav-link"></p>
          <p class="nav-item nav-link"></p>
          <p  class="nav-item nav-link"></p>
          <p class="nav-item nav-link"></p>
          <p  class="nav-item nav-link"></p>
      </nav>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="myw-section">
      <div class="table-responsive">

        <table class="table table-striped table-vmiddle myw-table">
          <thead style="height: 70px;">
            <tr style="padding-top: 0.5rem;
                            padding-bottom: 2.5rem;">
              <th scope="col">
              Clients
              </th>
              <th scope="col" >
                End-to-end projects
              </th>
              <th scope="col" >
                FTE's
              </th>
              <th scope="col" >
                Opportunities
          <div></div>
          <div></div>
                <div><span class="colmn3Span">Plan |</span> <span class="colmn3Span">Actual |</span> <span class="hidden-line actual-color">Total</span></div>                
              </th>
              <th scope="col" >
                Savings (M$)
                <div></div>
                <div></div>
                <div><span class="colmn3Span">Plan |</span> <span class="colmn3Span">Actual |</span> <span class="hidden-line actual-color">Total</span></div>
              </th>
              <th scope="col">
                # of myWizard Assets
              </th>
            </tr>
          </thead>

          <tbody>
            <tr  *ngFor="let opp of ClientList">       
            <td>
              <div  title="1">
                {{opp.ClientName}}
              </div>
            </td>
            <td>
              <div>
                {{opp.EndToEndName}}
              </div>
            </td>
            <td>
              <div>
                {{opp.FTE}}
              </div>
            </td>
            <td>
              <div >
             
                  <div><span class="colmn3Span">{{opp.OpportunitiesPlan | number}} |</span> <span class="colmn3Span">{{opp.OpportunitiesActual | number}} |</span> <span class="hidden-line actual-color">{{opp.OpportunitiesTotal | number}}</span></div>
              
              </div>
            </td>
            <td>
              <div >                
                  
                    <div><span class="colmn3Span">{{opp.SavingPlan | number : '1.2-2'}} |</span> <span class="colmn3Span">{{opp.SavingActual | number : '1.2-2'}} |</span> <span class="hidden-line actual-color">{{opp.SavingTotal | number : '1.2-2'}}</span></div>
                  
               
              </div>
            </td>
            <td>
              <div >
                {{opp.Asset}}
              </div>
            </td>
         
          </tr> 
          </tbody>
        </table>

      </div>
    </div>
    <!-- Pagination -->
    <br>
    <!-- <div style="float: right;">
      <pagination-controls (pageChange)="defaultPage = $event" style="display: inline-block;">
      </pagination-controls>
      <select (change)="changeNumberOfRecordsPerPage($event)">
        <option value="10">10/Page</option>
        <option value="20">20/Page</option>-->
        <!-- <option value="50">50/Page</option>
        <option value="100">100/Page</option>
        <option value="200">200/Page</option>
        <option value="300">300/Page</option>
        <option value="400">400/Page</option>
      </select>
    </div> --> 
</div>
</div>
</div>
<ngx-spinner fullScreen="false" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">

  <p style="font-size: 20px; color: white">Loading data...</p>

</ngx-spinner>

<router-outlet></router-outlet>
