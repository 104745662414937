import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from 'src/components/home/home.component';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
//import { AppComponent } from './app.component';
import { GovernanceComponent } from 'src/app/governance/governance.component';

const activateMSALGuard = environment.MSALenabled ? [MsalGuard] : [];

const routes: Routes = [
  {
    path: '', pathMatch: 'full', redirectTo: 'home'
  },
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'home', component: HomeComponent
  },
  // {
  //   path: 'home/MW/:endToEndId', component: HomeComponent
  // },
  // {
  //   path: 'home/mw/:endToEndId', component: HomeComponent
  // },
  {
    path: 'gov', component: HomeComponent
  },

  //TODO hhave to look at it
  // {
  //   path: 'gov', component: GovernanceComponent
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
