import { Component, HostListener, Input, OnInit } from '@angular/core';
import { common } from 'src/app/common/common';
import * as d3 from 'd3';
import { AJWebService } from 'src/services/ajWeb.service';

@Component({
  selector: 'mri-multiline-top-trending-assets',
  templateUrl: './multiline-top-trending-assets.component.html',
  styleUrls: ['./multiline-top-trending-assets.component.scss']
})
export class MultilineTopTrendingAssetsComponent implements OnInit {
  data: any;
  @Input() chartIdSelector: string;
  viewData = [];
  svg: any;
  margin = { top: 20, bottom: 180, left: 70, right: 50 };
  selector = 'top-trending-assets';
  yAxisText = "No. of Occurances";
  xAxisText = "";
  svgWidth: any;
  num: any;
  width: number;
  height: number;
  svgHeight = 380;
  barnumber = 7;
  page = 1;
  xScale: any;
  yScale: any;
  chart: any;
  barWidth = 24;
  bars: any;
  line_stroke_width = 2;
  circle_stroke_width = 3;
  radius = 3;
  cat2_color = "#DCDC67";
  cat1_color = "#DC67CE";
  legendColors = ["#DC67CE", "#DCDC67"];
  legends = ["Actual savings", "Asset Implementation cost"];

  @Input() monthsList: any;
  @Input() yearsList: any;
  @Input() currentDate: any;
  @Input() opportunityId: any;
  public highestValue: number = 0;

  public actualSavings: any[];
  public actualImplementation: any[];

  constructor(public ajWebService: AJWebService) { }

  ngOnInit() {
    //common.clearSVG(this.selector);
    this.ajWebService.displayROIGraphPopUp.subscribe(opportunityId => {
      this.opportunityId = opportunityId;
      this.getData();
    }, error => {
      this.ajWebService.showErrorMessage(error);
    })
  }

  getData(): any {
    this.ajWebService.GetSavingsValue(this.opportunityId).subscribe(roiData => {
      if (roiData != undefined) {
        this.actualSavings = roiData["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"];
        //this.actualImplementation = roiData["AttributeUsage"].find(x => x.AttributeName == "Automated Partially")["UsageValues"];

        let totalValueTillDate = 0;
        let monthYear: String = '';
        let finalData = [];

        let assetImplementationCost = 0;
        if (roiData["AssetImplementationCost"] != undefined) {
          for (var i = 0; i < roiData["AssetImplementationCost"].length; i++)
            assetImplementationCost += roiData["AssetImplementationCost"][i];

          for (var i = 1; i <= 12; i++) {
            monthYear = this.monthsList[this.currentDate.getMonth() + i] != undefined ? this.monthsList[this.currentDate.getMonth() + i] + this.yearsList[0] : this.monthsList[this.currentDate.getMonth() + i - 12] + this.yearsList[1];
            totalValueTillDate += this.actualSavings.find(x => x.Month == monthYear.substr(0, 3) + " " + monthYear.substr(3, 2)) != undefined ? this.actualSavings.find(x => x.Month == monthYear.substr(0, 3) + " " + monthYear.substr(3, 2)).Value : 0;

            finalData.push({
              "time": monthYear.substr(0, 3) + " " + monthYear.substr(3, 2),
              "value": 0,
              "category_1": totalValueTillDate,
              "cat1_cir": 1,
              "category_2": assetImplementationCost,
              "cat2_cir": 1
            })
          }
          this.data = finalData;
          this.drawGraph();
        } else {
          this.ajWebService.showInfoMessage("No data for asset implementation cost for this opportunity.");
          let elem = <HTMLElement>document.getElementsByClassName(this.selector)[0];
          if (elem != undefined)
            elem.innerHTML = "";
        }
      } else {
        this.ajWebService.showInfoMessage("No ROI data for this opportunity.");
        let elem = <HTMLElement>document.getElementsByClassName(this.selector)[0];
        if (elem != undefined)
          elem.innerHTML = "";
      }
    },
      error => {
        this.ajWebService.showInfoMessage(error.toString());
      })
  }

  FormatNumber(num) {
    if (num >= 100000000)
      return (num / 1000000) + "M";

    if (num >= 10000000)
      return (num / 1000000) + "M";

    if (num >= 100000)
      return (num / 1000) + "K";

    if (num >= 10000)
      return (num / 1000) + "K";

    return num.ToString("#,0");
  }


  drawGraph() {
    //this.viewData = this.transformData();
    this.viewData = this.data;
    let elem = <HTMLElement>document.getElementsByClassName(this.selector)[0];
    elem.innerHTML = "";
    this.svgWidth = 950; //elem.offsetWidth;
    this.width = this.svgWidth;

    //this.width =  elem.offsetWidth - this.margin.left - this.margin.right + 40;
    this.height = this.svgHeight - this.margin.top - this.margin.bottom;
    this.svg = this.getSVG();
    //alert(elem);
    this.xScale = this.getXScale();
    this.yScale = this.getYScale();
    this.chart = this.svg.append('g')
      .attr('class', 'svgstyle')
      .attr('transform', 'translate(' + [this.margin.left, this.margin.top + 12] + ')')
      .style('overflow', 'visible');
    this.renderXAxis();
    this.renderYAxis();
    this.renderYGridLines();
    this.renderXAxisText();
    this.renderYAxisText();
    this.renderLine();
    this.renderShadowpath();
    this.renderLineCircles();
    this.renderLegendText();
    this.renderLegendCircle();
  }

  // Line circles
  renderLineCircles() {
    const line_circles = this.chart.selectAll()
      .data(this.viewData)
      .enter()
      .append('g');

    // circles for category 1
    line_circles.append('circle')
      .attr('cx', d => this.xScale(d.time) + this.xScale.bandwidth() / 20)
      .attr('cy', d => this.yScale(d.category_1))
      .attr('r', this.radius)
      .attr('fill', d => {
        let color = 'none';
        if (d.cat1_cir == 0) {
          color = 'none';
        }
        else if (d.cat1_cir == 1) {
          color = this.cat1_color;
        }
        return color;
      })
      .attr('stroke', d => {
        let color = 'none';
        if (d.cat1_cir == 0) {
          color = 'none';
        }
        else if (d.cat1_cir == 1) {
          color = this.cat1_color;
        }
        return color;
      })
      .attr('stroke-width', 3)
      .append('svg:title')
      .text(d =>
        d.category_1
      );

    // circles for category 2
    line_circles.append('circle')
      .attr('cx', d => this.xScale(d.time) + this.xScale.bandwidth() / 20)
      .attr('cy', d => this.yScale(d.category_2))
      .attr('r', this.radius)
      .attr('fill', d => {
        let color = 'none';
        if (d.cat2_cir == 0) {
          color = 'none';
        }
        else if (d.cat2_cir == 1) {
          color = this.cat2_color;
        }
        return color;
      })
      .attr('stroke', d => {
        let color = 'none';
        if (d.cat2_cir == 0) {
          color = 'none';
        }
        else if (d.cat2_cir == 1) {
          color = this.cat2_color;
        }
        return color;
      })
      .attr('stroke-width', 3)
      .append('svg:title')
      .text(d =>
        d.category_2
      );
  }

  // Lines
  renderLine() {
    // const lineGroups = this.chart.selectAll()
    // .data(this.viewData)
    // .enter()
    // .append('g');

    var temp = this.chart.selectAll(".temp")
      .data(this.viewData)
      .enter().append("g")
      .attr("class", "temp");

    let line = d3.line()
      .x((d: any) => this.xScale(d.time) + this.xScale.bandwidth() / 20)
      .y((d: any) => this.yScale(d.category_1));
    //.curve(d3.curveCatmullRom.alpha(1));

    // Category line 1 STARTS
    const line_category1 = line.y((d: any) => this.yScale(d.category_1));
    this.chart.append("path")
      .attr("fill", "none")
      .attr("stroke-width", this.line_stroke_width)
      .attr("opacity", "1")
      .attr("stroke", this.cat1_color)
      .attr("d", line_category1(this.viewData))
      .attr("filter", "url(#dropshadow1)");

    // Category line 2 STARTS
    const line_category2 = line.y((d: any) => this.yScale(d.category_2));
    this.chart.append("path")
      .attr("fill", "none")
      .attr("stroke-width", this.line_stroke_width)
      .attr("opacity", "1")
      .attr("stroke", this.cat2_color)
      .attr("d", line_category2(this.viewData))
      .attr("filter", "url(#dropshadow1)");
  }

  renderXAxis() {
    this.chart.append('g')
      .attr('transform', `translate(-20, ${this.height + 4})`)
      .call(d3.axisBottom(this.xScale).tickSizeOuter(0))
      .call(d => {
        d.selectAll("path").remove();
        d.selectAll("line").remove();
        d.selectAll("text")
          .attr("font-family", "sans-serif")
          .attr("font-size", "10px");
      });

  }

  renderShadowpath() {
    /* For the drop shadow filter... */
    var defs = this.svg.append("defs");
    var filter = defs.append("filter")
      .attr("id", "dropshadow1");
    filter.append("feGaussianBlur")
      .attr("in", "SourceAlpha")
      .attr("stdDeviation", 1)
      .attr("result", "blur");
    filter.append("feOffset")
      .attr("in", "blur")
      .attr("dx", 3)
      .attr("dy", 3)
      .attr("result", "offsetBlur");

    filter.append("feFlood")
      .attr("in", "offsetBlur")
      .attr("flood-color", "#00000017")
      .attr("flood-opacity", "1")
      .attr("result", "offsetColor");

    filter.append("feComposite")
      .attr("in", "offsetColor")
      .attr("in2", "offsetBlur")
      .attr("operator", "in")
      .attr("result", "offsetBlur");

    var feMerge = filter.append("feMerge");

    feMerge.append("feMergeNode")
      .attr("in", "offsetBlur")
    feMerge.append("feMergeNode")
      .attr("in", "SourceGraphic");
  }

  renderYAxis() {
    this.chart.append('g')
      .attr('transform', `translate(${this.svgWidth}, 0)`)
      .attr('class', 'y-axis')
      // .call(d3.axisLeft(this.yScale).tickPadding(10).tickSizeOuter(0))
      .call(d => {
        d.selectAll("path")
          .attr("stroke", "#E4E4E4")
        d.selectAll("line").remove();
        d.selectAll("text").remove();
      });
  }

  renderYGridLines() {
    const makeYLines = () => d3.axisLeft(this.yScale).tickPadding(10)
      .ticks(6);
    const grid = this.chart.append('g')
      .attr('class', 'grid')
      .call(makeYLines().tickSize(-this.width))
      .call(d => d.select(".domain").attr("stroke", "#E4E4E4"));

    grid.selectAll("line")
      .attr("stroke", "#E4E4E4");

    grid.selectAll("text")
      .attr("font-family", "sans-serif")
      .attr("font-size", "10px");

  }

  renderXAxisText() {
    this.chart.append("text")
      .attr("transform",
        "translate(" + ((this.width / 4) - 200) + " ," +
        (this.height + this.margin.top + 25) + ")")
      .attr("text-anchor", "middle")
      .attr("font-family", "sans-serif Medium")
      .attr("font-size", "14px")
      .text(this.xAxisText);
  }

  renderYAxisText() {
    this.chart.append("text")
      .attr("transform", "rotate(360)")
      .attr("y", 0 - this.margin.left + 40)
      .attr("x", 0 - (this.height / 2) + 75)
      .attr("dy", "1em")
      .attr("text-anchor", "middle")
      .attr("font-family", "sans-serif Medium")
      .attr("font-size", "10px")
      .text(this.yAxisText);
  }

  renderLegendCircle() {
    this.svg.append("g")
      .attr("transform", `translate(${this.width / 3} , ${this.height + 90})`)
      .selectAll("myLegends")
      .data(this.legends)
      .join("circle")
      .attr("cx", (d, i) => 45 + i * 150)
      .attr("cy", 5)
      .attr("r", 5)
      .style("fill", (d, i) => this.legendColors[i])
  }

  renderLegendText() {
    this.svg
      .append("g")
      .attr("transform", `translate(${this.width / 3} , ${this.height + 90})`)
      .selectAll("mylabels")
      .data(this.legends)
      .join("text")
      .attr("x", (d, i) => 50 + i * 150)
      .attr("y", 5)
      .text((d, i) => d)
      .attr("text-anchor", "left")
      .style("alignment-baseline", "central")
      .style("font-size", "12px")
      .style("font-family", "sans-serif")
  }

  getXScale() {
    return d3.scaleBand()
      .range([0, this.width])
      .domain(this.viewData.map((d) => d.time))
      .padding(0.4);
  }

  getYScale() {
    return d3.scaleLinear()
      .rangeRound([this.height, 0])
      .domain([0, Math.round(Math.max(Math.max(...this.data.map(o => o.category_1), 0), Math.max(...this.data.map(o => o.category_2), 0)) * 1.2)]);
  }

  getSVG() {
    return common.getSVG(this.selector, this.svgWidth + 20, this.svgHeight);
  }

  transformData(): any[] {
    return this.data.slice((this.page - 1) * this.barnumber, this.page * this.barnumber);
  }

  nextPage(pageCount: number) {
    this.page = pageCount;
    this.viewData = this.data.slice((this.page - 1) * this.barnumber, this.page * this.barnumber);
    this.drawGraph();
  }

  prevPage(pageCount: number) {
    this.page = pageCount;
    this.viewData = this.data.slice((this.page - 1) * this.barnumber, this.page * this.barnumber);
    this.drawGraph();
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   common.clearSVG(this.selector);
  //   this.drawGraph();
  // }
}
