import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import * as d3 from 'd3';
import { element } from 'protractor';
import { common } from 'src/app/common/common';
import { AJWebService } from 'src/services/ajWeb.service';



@Component({
  selector: 'app-heat-map-chart',
  templateUrl: './heat-map-chart.component.html',
  styleUrls: ['./heat-map-chart.component.css']
})
export class HeatMapChartComponent implements OnInit {

  //@ViewChild('chart') private chartContainer: ElementRef;
  @Input() private data: Array<any>;
  @Input() HeatMapGraphData: any;
  //private margin: any = { top: 0, bottom: 40, left: 25, right: 25 };
  private chart: any;
  // private width: number;
  // private height: number;
  private xScale: any;
  private yScale: any;
  private colors: any;
  private xAxis: any;
  private yAxis: any;
  selector = 'app-heat-map-chart-section'; //'ticketDistHeatMap' ;
  //selector1 = 'ticketDistHeatMap'; //'ticketDistHeatMap' ;
  //selcetor = "heatmap-chart";
  myData: { variable: string; value: string; type: string; }[];
  margin = { top: 0, right: 25, bottom: 100, left: 60 };
  containerHeight = 470;
  width: number;
  height: number;
  svg: any;
  xAxisText = "Sub Category";
  yAxisText = "Queue Name";
  public displayTicketDistribution: any;

  constructor(public ajWebService: AJWebService) {

  }

  ngOnInit() {
    this.displayTicketDistribution = false;
    this.ajWebService.ticketDistributionDataLoaded.subscribe(opportunityQueues => {
      this.myData = this.ajWebService.ticketDistributionData;
      if (this.myData.length > 0) {
        this.displayTicketDistribution = true;
        let obj = this;
        setTimeout(function () {
          obj.drawGraph();
        }, 100)
      } else {
        this.displayTicketDistribution = false;
        let elem = <HTMLElement>(document.getElementsByClassName(this.selector)[0]);
        if (elem != undefined)
          elem.innerHTML = "";
      }
    }, error => {
      this.ajWebService.showErrorMessage(error);
    })
  }

  drawGraph() {
    this.renderSVGCOntainer();
    this.renderXAXis();
    this.renderYAxis();
    this.renderHeatMap();
    this.renderXAxisText();
    this.renderYAxisText();
    //this.renderHeatMaplegend();
  }


  renderSVGCOntainer() {
    //let elem = <HTMLElement>document.getElementsByClassName(this.selector)[0].parentElement.parentElement.parentElement;
    let elem = <HTMLElement>(document.getElementsByClassName(this.selector)[0]);
    if (elem != undefined)
      elem.innerHTML = "";

    // set the dimensions and margins of the graph     
    this.width = elem.offsetWidth - this.margin.left - this.margin.right;
    this.height = elem.offsetHeight - this.margin.top - this.margin.bottom;
    // this.width= 500;
    // this.height= 200;
    // append the svg object to the body of the page
    //   this.svg = common.getSVG(this.selector, this.width, this.height)
    //  .append("g")
    //  .attr("transform",
    //    "translate(" + this.margin.left + "," + this.margin.top + ")");
    let parentElement = <HTMLElement>document.getElementsByClassName(this.selector)[0].parentElement.parentElement;
    this.svg = d3.select(elem)
      .append('svg')
      .attr('width', parentElement.offsetWidth)
      .attr('height', parentElement.offsetHeight < 50 ? parentElement.offsetHeight : parentElement.offsetHeight - 50) //element.offsetHeight - this.margin.height);
      // .attr('transform', `translate(50,0)`);
      .append("g")
      .attr("transform",
        "translate(" + this.margin.left * 1.5 + "," + this.margin.top + ")");
  }

  x() {
    var myGroups = this.myData.map(d => d.type);
    // for (var i = 0; i < myGroups.length; i++)
    // myGroups[i].length >= 9 ? myGroups[i] = myGroups[i].slice(0, 8) + ".." : myGroups[i] = myGroups[i];
    return d3.scaleBand()
      .range([0, this.width])
      .domain(myGroups)
      .padding(0.02);
  }

  y() {
    var myVars = this.myData.map(d => d.variable);
    // for (var i = 0; i < myVars.length; i++)
    //   myVars[i].length >= 9 ? myVars[i] = myVars[i].slice(0, 8) + ".." : myVars[i] = myVars[i];
    return d3.scaleBand()
      .range([this.height, 0])
      .domain(myVars)
      .padding(0.2);
  }

  renderXAXis() {
    this.svg.append("g")
      .attr("class", "axis")
      .attr("transform", "translate(0," + (this.height) + ")")
      .call(d3.axisBottom(this.x()).ticks(10))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.6em")
      .attr("dy", ".10em")
      .attr("transform", "rotate(-65)")
      .text(d => d.length >= 9 ? d.slice(0, 8) + ".." : d)
      .append('svg:title')
      .text(d => d);
    // this.svg.append("g")
    //   .style("font-size", 10)
    //   .attr("transform", "translate(0," + (this.height) + ")")
    //   .call(d3.axisBottom(this.x()).tickSize(0).tickPadding(10))  //comment
    //   .select(".domain")  //comment
    //  .selectAll(".tick");
    //.selectAll("line").remove();
    //.call(g => g.select(".domain")).remove();
  }

  renderYAxis() {
    this.svg.append("g")
      .attr("transform", "translate(0,0)")
      // .style("font-size", 10)
      .attr("class", "yaxislabel")
      .call(d3.axisLeft(this.y()).ticks(10))
      .selectAll("text")
      .style("text-anchor", "start")
      .text(d => d.length >= 8 ? d.slice(0, 7) + ".." : d)
      .append('svg:title')
      .text(d => d);
    this.svg.selectAll(".yaxislabel text")
      .attr("transform", "translate(-45,0)")
  }

  renderXAxisText() {
    this.svg.append("text")
      .attr("class", "xAxisText")
      .attr("transform",
        "translate(" + ((this.height + this.width / 2) - 127.5) + " ," +
        (this.height + this.margin.bottom / 2 + 22) + ")")
      .attr("text-anchor", "middle")
      .attr("font-family", "sans-serif Medium")
      .attr("font-size", "11px")
      .text(this.xAxisText);
  }

  renderYAxisText() {
    this.svg.append("text")
      .attr("class", "yAxisText")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - (this.margin.left + this.margin.right))
      .attr("x", 0 - (this.height / 2))
      .attr("dy", "1em")
      .attr("text-anchor", "middle")
      .attr("font-family", "sans-serif Medium")
      .attr("font-size", "11px")
      .text(this.yAxisText);
  }
  renderHeatMap() {
    // Build color scale
    var colors: any = ["#fafafa", "#4008F4", "007afc", "3200fc"];
    //var colors:any =[ "#0000ff",'#6600ff','#944dff','#c299ff','#6969fc']
    var myColor = d3.scaleLinear()
      .domain([1, 6])
      .range(colors);

    // var myColor = d3.scaleLinear()
    // .range(['#ffffff', '#69b3a2'])
    // .domain([1, 100])

    // create a tooltip
    var tooltip = d3.select('#ticketDistHeatMap')
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "3px")
      .style("padding", "5px");

    // Three function that change the tooltip when user hover / move / leave a cell
    var mouseover = function (d) {
      tooltip
        .style("opacity", 1)
      d3.select(this)
        .style("opacity", 1)
    };
    var mousemove = function (d) {
      tooltip
        .html(d.variable + "</br>" + d.type + "</br> Tickets:" + d.value)
        .style("left", (d3.mouse(this)[0] - 150) + "px")
        .style("top", (d3.mouse(this)[1]) - 20 + "px")
    };
    var mouseleave = function (d) {
      tooltip
        .style("opacity", 0)
      d3.select(this)
        .style("opacity", 0.8)
    };

    // add the squares
    this.svg.selectAll()
      .data(this.myData, d => d.value)
      .enter()
      .append("rect")
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("x", d => this.x()(d.type))
      .attr("y", d => this.y()(d.variable))
      .attr("width", this.x().bandwidth() / 1.02)
      .attr("height", this.y().bandwidth())
      .style("fill", (d: any) => myColor(d.value))
      .style("stroke-width", 4)
      .style("stroke", "none")
      .style("opacity", 0.8)
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)
  }

  renderHeatMaplegend() {
    let parentElement = <HTMLElement>document.getElementsByClassName(this.selector)[0].parentElement.parentElement;
    var tranY = parentElement.offsetHeight - this.margin.bottom;
    var svg1 = this.svg
      .append("g")
      .attr("class", "legendsvg")
      .attr("width", parentElement.offsetWidth)
      .attr("height", this.margin.bottom)
      .attr("transform", "translate(" + -10 + "," + (parentElement.offsetHeight - 110) + ")");

    var legendGroup = svg1.selectAll(".legend")
      .data(this.myData, d => d.Value)
      .enter().append("g")
      .attr("transform", function (d, i) {
        return "translate(" + (i * 20) + "," + 0 + ")";
      });

    legendGroup.append("circle")
      .attr("cx", "20")
      .attr("cy", "55")
      .attr("r", 4)
      .data(this.myData, d => d.Value)
      .attr("fill", function (d, i) { // Add the colours dynamically
        return this.myColor(i);
      })
      .attr("stroke", function (d, i) { // Add the colours dynamically
        return this.myColor(i);
      })
      .attr("stroke-width", 2)

    legendGroup.append("text")
      .attr("x", 10)
      .attr("y", 20)
      .text(function (d) { return d.value; });

    // legendGroup.append('text')
    // //.attr("class", function (d) { return "legendText" + d.key.replace(/[^\w]/gi, '') })
    // .attr("id", "textLegend")
    // .style("color", "#9657D5")
    // .style("font-size", "11px")
    // //.style("font-weight", "bold")
    // .style("cursor", "pointer")
    // .style("fill", "#000")
    // .attr("text-anchor", "start")
    // .attr("x", "25")
    // .attr("y", "60")
    // .style('font-family', '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif')
    // .append('tspan')
    // .text(function (d) {
    //   if (this.myData.length > 50) { d =>d.value.toString(); }
    //   return d.toString();
    // })
  }


  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   if (event) {
  //     common.clearSVG(this.selector);
  //     this.drawGraph();
  //   }
  // }



}
