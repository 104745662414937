import { Component, HostListener, OnInit } from '@angular/core';
import { common } from 'src/app/common/common';
import * as d3 from 'd3';
import { json } from 'd3';
import { AJWebService } from 'src/services/ajWeb.service';

@Component({
  selector: 'mri-use-cases-implementation',
  templateUrl: './use-cases-implementation.component.html',
  styleUrls: ['./use-cases-implementation.component.scss']
})
export class UseCasesImplementationComponent implements OnInit {
  leftData: number[];
  rightData: number[];
  svg: any;
  viewData = [];
  type: any;
  selector = 'use-cases-implementation';
  selectorLegend = 'use-cases-implementation-legend';
  selector1 = 'x-axis__wrapper';
  total: any[];
  chart: any;
  data: any;
  xFrom: any;
  y: any;
  yScale: any;
  width: number;
  height: number;
  slice: any;
  slices: any;
  margin = { top: 20, bottom: 80, left: 70, right: 20 };
  maxValue = 0;
  public dataPresent: boolean = false;

  constructor(public ajWebService: AJWebService) { }

  ngOnInit() {
    let element = <HTMLElement>document.getElementsByClassName(this.selector)[0];
    this.width = element.offsetWidth - 20;
    this.height = 100;
    this.dataPresent = false;

    this.ajWebService.opportunityQueuesLoaded.subscribe(opportunityQueues => {
      this.data = this.ajWebService.opportunityQueues;
      let finalData = [];
      if (this.data != undefined && this.data.length > 0) {
        this.dataPresent = true;
        for (var i = 0; i < (this.data.length >= 4 ? 4 : this.data.length); i++) {
          finalData.push({
            "type": this.data[i]["queueName"],
            "values": [
              {
                "value": this.data[i].Complete.length,
                "category": "Category 1",
                "recordNo": i
              },
              {
                "value": this.data[i].Complete.length + this.data[i].InProgress.length,
                "category": "Category 2",
                "recordNo": i
              }
            ],
            "volume": this.data[i].InProgress.length + this.data[i].Complete.length,
            "turnAroundTime": this.data[i].Complete.length,
            "total": ""
          })
          if (this.data[i].InProgress.length + this.data[i].Complete.length > this.maxValue)
            this.maxValue = this.data[i].InProgress.length + this.data[i].Complete.length;
        }

        finalData = finalData.sort((a, b) => a.volume > b.volume ? 1 : -1);
        finalData.reverse();
        for (var i = 0; i < finalData.length; i++) {
          finalData[i]["recordNo"] = i;
          finalData[i].values[0]["recordNo"] = i;
          finalData[i].values[1]["recordNo"] = i;
        }
        finalData = finalData.sort((a, b) => a.recordNo > b.recordNo ? -1 : 1)
        var result = finalData.reduce((unique, o) => {
          if (!unique.some(obj => obj.type === o.type))
            unique.push(o);
          return unique;
        }, []);
        this.data = result;
        this.drawGraph();
      }
      else
      {
        element.innerHTML="";
      }
    }, error => {
      this.ajWebService.showErrorMessage(error);
    })
  }

  drawGraph() {
    this.prepareData();
    this.renderXYaxis();
    this.renderBars();
    this.renderBarText();
  }

  renderXYaxis() {
    let noOfData = this.type.length ? this.type.length : 1;
    let chartHeight = 5 * noOfData;
    let elem_wrapper = <HTMLElement>document.getElementsByClassName(this.selector)[0];
    let elem = <HTMLElement>document.getElementsByClassName(this.selector)[0];
    elem.innerHTML = "";
    this.chart = common.getSVG(this.selector, elem_wrapper.offsetWidth + 50, elem_wrapper.offsetHeight);
    this.width = elem_wrapper.offsetWidth;
    this.height = chartHeight + 120;

    this.xFrom = d3.scaleLinear()
      .domain([d3.max(this.leftData) + 10, 0])
      .range([0, this.width / 2])
      .nice();

    this.yScale = d3.scaleBand()
      .domain(this.type)
      .rangeRound([10, this.height])
      .paddingInner(0.6);
    this.xFrom.range([0, this.width / 2]);
  }

  prepareData() {
    this.leftData = [];
    this.rightData = [];
    this.type = [];
    this.total = [];
    this.data.forEach(element => {
      this.leftData.push(element.volume);
      this.rightData.push(element.turnAroundTime);
      this.type.push(element.type);
      this.total.push(element.total);
    });

  }

  renderBars() {
    const rx = 6;
    const ry = 6;
    const gap = 22;
    this.chart.selectAll("rect.left")
      .data(this.data)
      .enter().append('path')
      .attr('class', 'bar-left')
      .attr("d", d => `
      M${this.width / 2 - gap - 2},${(d.recordNo * 45) + 22}
      h${d.volume > 0 ? ((rx - (this.width / 2 - this.xFrom(d.volume)))<=(-100)?(-100):((((rx - (this.width / 2 - this.xFrom(d.volume))) )<0 ?(rx - (this.width / 2 - this.xFrom(d.volume))):(-(rx - (this.width / 2 - this.xFrom(d.volume))))))) : 0}
      a${d.volume > 0 ? rx : 0},${d.volume > 0 ? ry : 0} 0 0 0 ${d.volume > 0 ? -rx : 0},${d.volume > 0 ? ry : 0}
      v 2
      a${d.volume > 0 ? rx : 0},${d.volume > 0 ? ry : 0} 0 0 0 ${d.volume > 0 ? rx : 0},${d.volume > 0 ? ry : 0}
      h${-(d.volume > 0 ? ((rx - (this.width / 2 - this.xFrom(d.volume)))<=(-100)?(-100):((((rx - (this.width / 2 - this.xFrom(d.volume))) )<0 ?(rx - (this.width / 2 - this.xFrom(d.volume))):(-(rx - (this.width / 2 - this.xFrom(d.volume))))))) : 0)}Z
    `)
      .attr('fill', "#7235B1");

    var xTo = d3.scaleLinear()
      .domain([0, d3.max(this.rightData) + 20])
      .range([0, this.width / 2])
      .nice();

    xTo.range([0, this.width / 2]);

    this.chart.selectAll("rect.right")
      .data(this.data)
      .enter().append('path')
      .attr('class', 'bar')
      .attr("d", d => `
          M${this.width / 2 + gap + 4},${(d.recordNo * 45) + 22}
          h${xTo(d.turnAroundTime) - (d.turnAroundTime > 0 ? rx : 0)}
          a${d.turnAroundTime > 0 ? rx : 0},${d.turnAroundTime > 0 ? ry : 0} 1 0 1 ${d.turnAroundTime > 0 ? rx : 0},${d.turnAroundTime > 0 ? ry : 0}
          v 2
          a${d.turnAroundTime > 0 ? rx : 0},${d.turnAroundTime > 0 ? ry : 0} 1 0 1 ${d.turnAroundTime > 0 ? -rx : 0},${d.turnAroundTime > 0 ? ry : 0}
          h${(d.turnAroundTime > 0 ? rx : 0) - (xTo(d.turnAroundTime))}Z
        `)
      .attr('fill', "#DC30C5");
  }

  renderBarText() {
    this.chart.selectAll("text.type")
      .data(this.data)
      .enter().append("text")
      .attr("font-family", "sans-serif")
      .attr("font-size", "9.5px")
      .attr("x", d => (this.width / 2))
      .attr("y", d => (d.recordNo * 45) + 15)
      .attr("text-anchor", "middle")
      .attr('class', 'type-text')
      .attr('fill', '#4D4F5C')
      .text(d =>
        d.type + "" + d.total + ""
      );

    // this.chart.selectAll("text.total")
    //   .data(this.data)
    //   .enter().append("text")
    //   .attr("font-family", "sans-serif")
    //   .attr("font-size", "10px")
    //   .attr("x", d => (this.width / 2) + 23)
    //   .attr("y", d => this.yScale(d.type) + (this.yScale.bandwidth() / 2) + 15)
    //   .attr("text-anchor", "end")
    //   .attr('class', 'type-text')
    //   .attr('fill', '#4D4F5C')
    //.text(d => 'Total' + " " + d.total)

    this.renderSlices()
      .append("text")
      .attr('transform', (d, i) => {
        if (d.category == 'Category 1')
          if (d.recordNo == 4)
            return `translate(${72} ${-12})`
          else
            return `translate(${72} ${-12})`
        else if (d.category == 'Category 2')
          if (d.recordNo == 4)
            return `translate(${52} ${-12})`
          else
            return `translate(${52} ${-12})`
      })
      .attr("text-anchor", "middle")
      .attr("font-family", "sans-serif")
      .attr("font-size", "12px")
      .attr('fill', 'black')
      .text(d => {
        if (d["category"] == "Category 2")
          return `${d.value + " | "}`
        else
          return `${d.value}`
      })
  }

  renderSlices() {
    return this.slices = this.renderSlice().selectAll("path")
      .data(
        d => d.values
      )
      .enter();
  }

  renderSlice() {
    this.viewData = this.data;
    return this.slice = this.chart.selectAll(".slice")
      .data(this.viewData)
      .enter().append("g")
      .attr("class", "g")
      .attr("id", "groups")
      .attr("transform", d =>
        "translate(" + [this.margin.left + 5] + "," + (d.recordNo + 1) * 45 + ")"
      )
  }

  xScale() {
    return d3.scaleLinear()
      .domain([0, 1.2 * this.maxValue]).nice()
      .range([0, this.width]);
  }

  y0Scale() {
    return d3.scaleBand()
      .range([this.height, 0])
      .domain(this.viewData.map((s) => s.month))
      .padding(0.1);
  }

  public getLegendSVG() {
    return d3.select(`#${this.selectorLegend}​​`)
      .append('svg')
      .attr('id', 'app-legend-svg')
      .attr('class', 'svg-legend-style')
      .attr('width', 100)
      .attr('height', 100)
      .append('g');
  }

  renderLegends() {
    let lables = ["Defect", "Flag", "Good"];
    let colors = ["black", "red", "grey"];

    const svg = this.getLegendSVG()
      .selectAll('lables')
      .data(lables)
      .enter()
      .attr("class", "legend")
      .attr("transform", "translate(50,30)")
      .style("font-family", "sans-serif")
      .style("font-size", "12px");

    let xPosition = 10;
    let yPosition = 20;
    let size = 20;
    svg.append('circle')
      .attr('cx', xPosition)
      .attr('cy', (d, i) => yPosition + i * (size + 5))
      .attr('r', 7)
      .style('fill', (d) => 'black');

    svg.append('text')
      .attr('x', xPosition + size * 1.2)
      .attr('y', (d, i) => yPosition + i * (size + 5) + (size / 2.5))
      .style('fill', 'black').text((d) => d).attr('text-anchor', 'left')
      .style('alignment-baseline', 'middle');
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   common.clearSVG(this.selector);
  //   this.drawGraph();
  // }
}
