import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { AppConfig } from '../auth/app.config';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    public environmentSettings: any;
    private http: HttpClient;

    constructor(private readonly httpHandler: HttpBackend) {
        this.http = new HttpClient(httpHandler);
    }

    public init(endpoint: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.http.get(endpoint).pipe(map(res => res)).subscribe(value => {
                this.environmentSettings = value;
                resolve(true);
            },
                (error) => {
                    reject(error);
                });
        });
    }

    getSettings(key?: string | Array<string>): any {
        if (!key || (Array.isArray(key) && !key[0]))
            return this.environmentSettings;

        if (!Array.isArray(key))
            key = key.split('.');

        let result = key.reduce((acc: any, current: string) => acc && acc[current], this.environmentSettings);
        return result;
    }
}