import { Component, OnInit, Input } from '@angular/core';
import { AJWebService } from 'src/services/ajWeb.service';

@Component({
  selector: 'app-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.css']
})
export class CommentBoxComponent implements OnInit {
  public comment:string="";
  public existingComment:string="";
  @Input() opportunityId: any;


  constructor(public ajWebService: AJWebService) { }

  ngOnInit(): void {
    this.ajWebService.displayComment.subscribe(opportunityId=>{
      this.opportunityId=opportunityId;
      this.getdata();
    },error=>{
      this.ajWebService.showErrorMessage(error);
    })
  }

  getdata(){
    this.ajWebService.GetComment(this.opportunityId).subscribe(response=>{
      this.comment=response;
    }, error=>{
      this.ajWebService.showErrorMessage(error);
    })
  }

  addComment(comment:string){
    if (comment.length<=500){
      this.ajWebService.AddComment(this.opportunityId,comment).subscribe(response=>{
        this.ajWebService.showSuccessMessage("Comment Saved")
      }, error=>{
        this.ajWebService.showErrorMessage(error);
      }); 
    }
    else{
      this.ajWebService.showErrorMessage("Comment Exceeds 500 Characters")
    }
  }

  clearComment(){
    if(this.comment!=""){
       this.comment = "";
      this.ajWebService.AddComment(this.opportunityId,this.comment).subscribe(response=>{
        var data = response;
        this.ajWebService.showSuccessMessage("Comment Cleared");
      }, error=>{
        this.ajWebService.showErrorMessage(error);
      })
    }
  }
}
