import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchOpportunityList',
    pure: false
})
export class SearchOLPipeComponent implements PipeTransform {
    transform(searchList: any[], searchText: Object): any {
        if (!searchList || !searchText)
            return searchList;
        return searchList.filter(item => item["OpportunityName"].indexOf(searchText["title"]) !== -1);
    }
}