import { Component, HostListener, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { common } from 'src/app/common/common';
import { AJWebService } from '../services/ajWeb.service';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})

export class DonutChartComponent implements OnInit {
  dataset: any;
  selector = 'donut_chart-section';
  width = 290;
  height = 230;
  margin = 20;
  radius: any;
  svg: any;
  labelValue = 'Opportunities';
  totalCount = 0;
  color = ['#6D6ACC', '#7188DC', '#67CABD', '#DC67CE'];

  constructor(public ajWebService: AJWebService) { }

  ngOnInit() {
    this.ajWebService.loadAutomationOpportunityData.subscribe(opportunityQueues => {
      common.clearSVG(this.selector);
      let element = <HTMLElement>document.getElementsByClassName(this.selector)[0];
      this.width = element.offsetWidth + 5;
      this.height = 200;
      this.dataset = this.getData();
      this.drawGraph();
    }, error => {
      this.ajWebService.showErrorMessage(error);
    })
  }

  drawGraph() {
    //this.radius = (Math.min(this.width, this.height) / 2) - this.margin;
    this.radius = 70;
    this.svg = this.getSVG();
    this.renderPolyLineCircles();
    this.renderCenterTotalCountText();
    this.renderCenterLable();
    this.renderLegends();
  }

  renderLegends() {
    let nodeWidth = (d) => d.getBBox().width;
    const legend = this.svg.append('g')
      .attr('transform', 'translate(0,0)');

    const lg = legend.selectAll('g')
      .data(this.dataset)
      .enter()
      .append('g')

    let legendRadius = 5;
    lg.append('circle')
      .attr('fill', (d, i) => this.color[i])
      .attr('r', legendRadius);

    lg.append('text')
      .attr("font-family", "sans-serif")
      .attr("font-size", "10px")
      .attr('fill', '#000000')
      .attr("text-align", "centre")
      .attr("x", legendRadius + 2)
      .attr("dy", "0.3em")
      .text(d => d.label);

    let offset = 10;
    let offset1 = 10;
    let height = this.height;
    let width = this.width;
    lg.attr('transform', function (d, i) {
      let x = offset;
      offset += nodeWidth(this) + 10;
      if (offset < width + 48) {
        return `translate(${x + 15.5},${height / 2 - 15})`;
      }
      else {
        let x = offset1;
        offset1 += nodeWidth(this) + 15;
        return `translate(${x + 15.5},${height / 3})`;
      }
    });
    let radius = this.radius;
    legend.attr('transform', function () {
      return `translate(${(-radius - 30)},${25})`
    });
  }

  renderCenterLable() {
    this.svg.append('text')
      .attr('font-family', 'sans-serif Medium')
      .attr('font-size', '9.5px')
      .attr('dy', '2em')
      .attr('fill', '#B0B0B0')
      .attr('text-anchor', 'middle')
      .text(this.labelValue);
  }

  renderPolyLineCircles() {
    const mainArc = this.getMainArc();
    // Another arc that won't be drawn. Just for labels positioning
    let line_length = this.radius + 30,
      arc_outer_line = d3.arc()
        .innerRadius(line_length)
        .outerRadius(line_length);

    let arc_outer_value = d3.arc()
      .innerRadius(line_length + 18)
      .outerRadius(line_length + 18);

    const pie = d3.pie()
      .value((d: any) => d.percentage)
      .sort(null);

    const paths = this.svg.selectAll('g')
      .data(pie(this.dataset))
      .enter();

    // Add the polylines between chart and labels:
    // paths.append('polyline')
    //   .attr('stroke', 'black')
    //   .style('fill', 'none')
    //   .attr('stroke-width', 1)
    //   .attr('points', (d) => {
    //     const posA = mainArc.centroid(d), posB = arc_outer_line.centroid(d);
    //     return [posA, posB];
    //   });

    // Add circle to the polyline
    // paths.append('circle')
    //   .attr('r', 3)
    //   .attr('transform', d => 'translate(' + arc_outer_line.centroid(d) + ')')
    //   .attr('fill', (d, i) => this.color[i]);

    // Add values to the charts
    // paths.append('text')
    //   .attr('transform', d => 'translate(' + arc_outer_value.centroid(d) + ')')
    //   .attr('font-family', 'sans-serif')
    //   .attr('font-size', '12px')
    //   .attr('fill', '#B0B0B0')
    //   .attr('dy', '.35em')
    //   .attr('text-anchor', 'middle')
    //   .text(d => d.value + '%');

    paths.append('path')
      .attr('d', mainArc)
      .attr('fill', (d, i) => this.color[i])
      .append('svg:title')
      .text(d =>
        Math.round(+d.data.label.substr(d.data.label.indexOf(' ') + 1, 10))
      );
  }

  renderCenterTotalCountText() {
    this.svg.append('text')
      .attr('font-family', 'sans-serif Black')
      .attr('font-size', '35px')
      .attr('dy', '0.25em')
      .attr('fill', '#000000')
      .attr('text-anchor', 'middle')
      .text(this.totalCount);
  }

  getMainArc() {
    return d3.arc()
      .innerRadius(d => this.radius * 0.7)
      .outerRadius(d => this.radius)
      .padAngle(0.025);
  }


  getSVG() {
    return common.getSVG(this.selector, this.width, this.height)
      .append('g')
      .attr('transform', 'translate(' + (this.width / 2) +
        ',' + (this.height / 2.5) + ')');
  }

  getData() {
    this.totalCount = this.ajWebService.backlogList.length + this.ajWebService.inProgressList.length + this.ajWebService.completedList.length + this.ajWebService.cancelledList.length;
    return [
      { percentage: Math.round(this.ajWebService.backlogList.length / this.totalCount * 100), label: 'Backlogs ' + this.ajWebService.backlogList.length },
      { percentage: Math.round(this.ajWebService.inProgressList.length / this.totalCount * 100), label: 'In-Progress ' + this.ajWebService.inProgressList.length },
      { percentage: Math.round(this.ajWebService.completedList.length / this.totalCount * 100), label: 'Completed ' + this.ajWebService.completedList.length },
      { percentage: Math.round(this.ajWebService.cancelledList.length / this.totalCount * 100), label: 'Cancelled ' + this.ajWebService.cancelledList.length }
    ];
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   if (event) {
  //     common.clearSVG(this.selector);
  //     this.drawGraph();
  //   }
  // }
}
