<!-- <div class="header">
    <p class="header-text">Use Cases Implemented</p>
</div> -->
<div class="identified-oppurtunities__wrapper" style="margin-left: 0;">
    <div id="use-cases-implementation" class="use-cases-implementation"></div>
    <div class="center_align" *ngIf="dataPresent">
        <ul class="info-values">
            <li class="val planned" style="font-family: sans-serif; font-size: 11px;">Planned</li>
            <li class="val completed" style="font-family: sans-serif; font-size: 11px;">Completed<li>
        </ul>
    </div>
</div>