import { Component, OnInit, HostListener } from '@angular/core';
import * as d3 from "d3";
import { common } from 'src/app/common/common';
import { AJWebService } from 'src/services/ajWeb.service';

@Component({
  selector: 'effort-bullet-chart',
  templateUrl: './outcomes_TotEffort_bullet-chart.component.html',
  styleUrls: ['./outcomes_TotEffort_bullet-chart.component.scss']
})
export class outcomes_TotEffort_BulletChartComponent implements OnInit {

  data: any;
  selector = "bullet-chart-section1";
  //margin = { top: 10, bottom: 40, left: 70, right: 20 };
  margin = { top: 5, bottom: 40, left: 2, right: 2 };
  svg: any;
  width: number;
  height: number;
  graphWidth: number;
  chart: any;
  bar_width = 14;
  rx = this.bar_width / 2;
  ry = this.bar_width / 2;
  rangeVal = [];
  rangeColor = ['#F0F0F1', '#e3e6e8', '#cbd0d6', '#bac0cc', '#aab0bd', '#FFFFFF'];
  legendKey = ["Planned", "Completed"];
  legendColor = ["#7235B1", "#DC30C5"];
  xAxisLabel = "Total Effort (Hrs)";
  maxValue: any;

  constructor(public ajWebService: AJWebService) { }

  ngOnInit() {
    this.ajWebService.outcomesDataCalculationCompleted.subscribe(opportunityQueues => {
      common.clearSVG(this.selector)
      this.drawGraph();
    }, error => {
      this.ajWebService.showErrorMessage(error);
    })
  }

  drawGraph() {
    this.data = this.getData();
    this.renderSVGContainer();
    this.renderRangeRect();
    this.renderXAxis();
    this.renderMeasure();
    this.renderMark();
    this.renderAxisText();
    this.renderLegend();
  }

  getData() {
    return [{
      "title": "Actual",
      "measures": Math.round(this.ajWebService.costPerHour != 0 ? this.ajWebService.actualTotalSavings / this.ajWebService.costPerHour : 0),
      "markers": Math.round(this.ajWebService.plannedTotalEfforts)
    }];
  }

  renderSVGContainer() {
    let elem = <HTMLElement>(
      document.getElementsByClassName(this.selector)[0]
    );
    elem.innerHTML = "";
    this.svg = common.getSVG(this.selector, '100%', '100%');

    this.width = elem.offsetWidth - this.margin.left - this.margin.right;
    this.height = elem.offsetHeight - this.margin.top - this.margin.bottom;

    this.graphWidth = this.width - 100;
  }

  xScale() {
    return d3.scaleLinear()
      .range([0, this.graphWidth])
      .domain([0, this.maxValue])
      .nice();
  }

  yScale() {
    return d3.scaleBand()
      .range([0, this.height])
      .padding(0.2);
  }

  renderChartContainer() {
    return this.chart = this.svg
      .append("g")
      .attr(
        "transform",
        "translate(" + [this.margin.left + 50, this.margin.top - 70] + ")"
      );
  }

  renderXAxis() {
    this.renderChartContainer().append("g")
      .attr("transform", `translate(0 , ${this.height + 17})`)
      .call(d3.axisBottom(this.xScale()).tickSizeOuter(0)
        .ticks(6).tickValues(this.rangeVal).tickFormat(d => d >= 1000000 ? +d / 1000000 + 'M' : d >= 1000 ? +d / 1000 + 'K' : d + ''))
      .call((d) => {
        d.selectAll("path").remove();
        d.selectAll("line").remove();
        d.selectAll("text")
          .attr("font-size", "10px")
      });
  }

  renderRangeRect() {
    let maxValue = Math.round(1.2 * Math.max(Math.round(this.ajWebService.costPerHour != 0 ? this.ajWebService.actualTotalSavings / this.ajWebService.costPerHour : 0), Math.round(this.ajWebService.plannedTotalEfforts)));
    if (maxValue >= 1000000)
      maxValue = Math.ceil(maxValue / 100000) * 100000
    else if (maxValue >= 100000)
      maxValue = Math.ceil(maxValue / 10000) * 10000
    else if (maxValue >= 10000)
      maxValue = Math.ceil(maxValue / 1000) * 1000
    else if (maxValue >= 1000)
      maxValue = Math.ceil(maxValue / 100) * 100

    if ((maxValue - (maxValue % 5000)) > Math.max(Math.round(this.ajWebService.costPerHour != 0 ? this.ajWebService.actualTotalSavings / this.ajWebService.costPerHour : 0), Math.round(this.ajWebService.plannedTotalEfforts)))
      maxValue = maxValue - (maxValue % 5000);
    else
      maxValue = maxValue - (maxValue % 5000) + 5000;

    this.maxValue = maxValue;

    this.rangeVal = [];
    for (var i = 5; i >= 0; i--)
      this.rangeVal.push(maxValue * i / 5);

    var range = this.renderChartContainer()
      .selectAll()
      .data(this.rangeVal)
      .enter()
      .append("g")

    range.append('rect')
      .attr('class', 'in-range')
      .attr("x", -8)
      .attr('y', this.yScale().bandwidth() - this.rx * 2 + 12)
      .attr('height', this.bar_width * 3)
      .attr('width', (d) => this.xScale()(d) + 8)
      .attr('fill', (d, i) => this.rangeColor[i]);

    range.append('rect')
      .attr('class', 'out-range')
      .attr("x", -5)
      .attr('y', this.yScale().bandwidth() - this.rx * 2 + 12)
      .attr('rx', this.rx)
      .attr('ry', this.ry)
      .attr('height', this.bar_width * 3)
      .attr('width', this.graphWidth + 8)
      .attr('fill', 'none')
      .attr('stroke', 'white')
      .attr('stroke-width', 8);
  }

  renderMeasure() {
    var mes = this.renderChartContainer()
      .selectAll()
      .data(this.data)
      .enter()
      .append("g")
      .attr("class", "measure")
      .style("fill", this.legendColor[1]);
    mes.append("path")
      .attr("d", (d, i) => {
        return `M${0} ${this.yScale().bandwidth() - this.ry + 18}
              h${(this.xScale()(d.measures) < this.rx ? this.rx - this.xScale()(d.measures) : this.xScale()(d.measures) - this.rx)}
              a${this.rx} ${this.ry} 0 0 1 ${this.rx} ${this.ry}
              v 4
              a${this.rx} ${this.ry} 0 0 1 ${-this.rx} ${this.ry}
              h${(this.xScale()(d.measures) < this.rx ? this.xScale()(d.measures) - this.rx : this.rx - this.xScale()(d.measures))}Z`
      }
      ).append('svg:title')
      .text(function (d, i) { return d["measures"] + "hrs"; })
    // .text(d =>
    //   d["measures"]
    // )
  }

  renderMark() {
    let mark = this.renderChartContainer()
      .selectAll(".marker")
      .data(this.data)
      .enter()
      .append('g')
      .attr("class", "marker")

    mark.append("line")
      .attr("x1", d => this.xScale()(d.markers))
      .attr("x2", d => this.xScale()(d.markers))
      .attr("y1", this.yScale().bandwidth() - this.bar_width + 17)
      .attr("y2", this.yScale().bandwidth() + this.bar_width + 21)
      .attr("stroke-width", 3)
      .attr("stroke", this.legendColor[0])
      .attr('fill', this.legendColor[0])
      .append('svg:title')
      .text(function (d, i) { return d["markers"] + "hrs"; })
    // .text(d =>
    //   d["markers"]
    // );
  }

  renderAxisText() {
    this.renderChartContainer().append("text")
      .attr("transform",
        "translate(" + (this.graphWidth / 2) + " ," + (this.height - 30) + ")")
      .attr("text-anchor", "middle")
      .attr("font-family", "sans-serif Strong")
      .attr("font-size", "12px")
      .attr("font-weight", "bold")
      .text(this.xAxisLabel);
  }


  renderLegend() {
    var nodeWidth = (d) => d.getBBox().width;
    const legend = this.renderChartContainer().append('g')
      .attr('class', 'legend')
      .attr('transform', 'translate(0,0)');

    const lg = legend.selectAll('g')
      .data(this.legendKey)
      .enter()
      .append('g')
      .attr("class", (d, i) => "circlelegend" + i)

    lg.append('circle')
      .attr("id", (d, i) => "circle" + i)
      .attr('fill', (d, i) => this.legendColor[i])
      .attr('cx', 0)
      .attr('cy', -20)
      .attr('r', 5)

    lg.append('text')
      .attr("font-family", "sans-serif")
      .attr("font-size", "11px")
      .attr('fill', '#515151')
      .attr('x', 10)
      .attr('y', -15)
      .attr('dy', -1)
      .text((d, i) => this.legendKey[i]);

    let offset = 0;
    const self = this;
    lg.attr('transform', function (d, i) {
      let x = offset;
      offset += nodeWidth(this) + 10;
      return `translate(${x},${self.height + 10})`;
    });

    legend.attr('transform', function () {
      return `translate(${(self.graphWidth - nodeWidth(this)) / 2},${81})`
    });
  }

  // @HostListener("window:resize", ["$event"])
  // onResize(event) {
  //   common.clearSVG(this.selector);
  //   this.drawGraph();
  // }
}
