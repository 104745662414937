import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const httpRequest = req.clone({
      headers: req.headers.append('Cache-Control', 'no-cache').append('Pragma', 'no-cache').append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      //.append('Access-Control-Allow-Origin', '*')
    });
    return next.handle(httpRequest);
  }
} 
