import { InjectionToken, NgModule, APP_INITIALIZER } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  // MSAL_CONFIG_ANGULAR,
  MsalGuardConfiguration
  , MsalService, MsalModule, MsalInterceptor, MSAL_INSTANCE, MsalGuard, MsalInterceptorConfiguration, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfigService } from '../services/app-config.service';
import { Configuration } from 'msal';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { env } from 'process';
import { HttpTokenInterceptor } from 'src/services/HttpTokenInterceptor';

const AUTH_CONFIG_URL_TOKEN = new InjectionToken<string>('AUTH_CONFIG_URL');

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function initializerFactory(env: AppConfigService, configUrl: string): any {
  // APP_INITIALIZER, except a function return which will return a promise
  // APP_INITIALIZER, angular doesnt starts application untill it completes
  const promise = env.init(configUrl).then((value) => {
    //console.log(env);
  });
  return () => promise;
}
export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}
///END 
export function MSALInstanceFactory(config: AppConfigService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: config.getSettings('webClientID'),
      authority: config.getSettings('authority'),
      redirectUri: config.getSettings('azureRedirectURL'),
      postLogoutRedirectUri: config.getSettings("azureRedirectURL"),
      //navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}
export function MSALInterceptorConfigFactory(config: AppConfigService): MsalInterceptorConfiguration {

  var apiid = config.getSettings('apiClientID');
  var msalurls = config.getSettings('msalurl');
  console.log(apiid);
  console.log(msalurls);

  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(msalurls, [apiid + '/user_impersonation', apiid + '/user_profile']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {

  return {
    interactionType: InteractionType.Redirect,
    // authRequest: {
    //   scopes: [
    //     'User.Read'
    //   ]
    // }
  };
}

@NgModule({
  providers: [
  ],
  imports: [MsalModule]
})
export class MsalApplicationModule {
  static forRoot(configFile: string) {
    return {
      ngModule: MsalApplicationModule,
      providers: [
        AppConfigService,
        { provide: AUTH_CONFIG_URL_TOKEN, useValue: configFile },
        {
          provide: APP_INITIALIZER, useFactory: initializerFactory,
          deps: [AppConfigService, AUTH_CONFIG_URL_TOKEN], multi: true
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          //useClass: HttpTokenInterceptor,
          multi: true
        },

        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [AppConfigService]
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [AppConfigService]
        },
      ]
    };
  }
}
